import React, { createContext, useEffect, useState } from 'react';
import { useIPandFeedbackLogger } from '../IPLogAndFeedbackBackend';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [userEmail, setUserEmail] = useState('');
    const [user_ID, setUser_ID] = useState();
    const localHostDisplayName = localStorage.getItem('realDisplayName') || '';
    const localHostUID = localStorage.getItem('firebaseUID') || '';
    const [realDisplayName, setRealDisplayName] = useState(localHostDisplayName);
    const [firebaseUID, setFirebaseUID] = useState(localHostUID);
    const { userIP } = useIPandFeedbackLogger();

    useEffect(() => {
        if (realDisplayName) {
            localStorage.setItem('realDisplayName', realDisplayName);
        }
    }, [realDisplayName]);

    useEffect(() => {
        if (firebaseUID) {
            localStorage.setItem('firebaseUID', firebaseUID);
        }
    }, [firebaseUID]);

    useEffect(() => {
        if (firebaseUID) {
            setUser_ID(firebaseUID as any);
        } else {
            setUser_ID(userIP);
        }
    }, [userIP, firebaseUID]);

    return (
        <UserContext.Provider
            value={{
                user_ID,
                setUser_ID,
                setUserEmail,
                userEmail,
                setRealDisplayName,
                userIP,
                realDisplayName,
                setFirebaseUID,
                firebaseUID
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
