import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiGatewayClient } from '../components/utils';

export const isSale = true;

export interface Product {
    price_id: string,
    product_id: string,
    title: string,
    price: number,
    color: string,
    size: string,
    imageUrl: string, // You'll need to determine how to handle images.
    imageId: string, // This might also need adjustment based on your backend.
    presalePrice?: number,
    // Consider adding any new fields here, like `description`.
}

export function useProducts() {
    const [products, setProducts] = useState<Product[]>([]);

    useEffect(() => {
        const getProducts = async () => {
            const response = await apiGatewayClient({
                path: 'get_products',
                method: 'GET',
                queryParams: {}
            });
            const data = await response.json();
            const transformedProducts = data.map((item) => ({
                price_id: item.min_price_id,
                product_id: item.product_id,
                title: item.name,
                price: item.min_price,
                color: item.color, // Example default, you might want to adjust this
                size: 'Default', // This should be adjusted based on your needs
                imageUrl: '', // Determine how you handle images
                imageId: '', // Same as above
                presalePrice: item.min_price,
                available_colors: item.available_colors,
                product_images: item.product_images
                // Add mapping for any new fields here
            }));
            setProducts(transformedProducts);
        };

        getProducts();
    }, []); // Empty dependency array means this effect runs once on mount

    return products;
}

const ProductContext = createContext([]);

export const useGlobalProducts = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    const products = useProducts();

    return (
        <ProductContext.Provider value={products}>
            {children}
        </ProductContext.Provider>
    );
};