import React, { createContext, useState } from 'react';

export interface Image {
    imageId: string,
    imageUrl: string,
    defaultShirtColor: string,
    timestamp: string,
    seed: string,
    base64?: string,
    favorited?: boolean,
    rootImageId?: string,
    baseImageId?: string,
}

const ImageContext = createContext({
    selectedBase64: null,
    selectedImageID: null,
    setSelectedBase64: () => {},
    setSelectedImageID: (imageId: string) => {},
    selectedImageURL: () => {},
    setSelectedImageURL: (imageUrl: string) => {},
    selectedImageDefaultColor: null,
    setSelectedImageDefaultColor: (defaultShirtColor: string) => {}
});

export const ImageProvider = ({ children }) => {
    const [selectedBase64, setSelectedBase64] = useState(null);
    const [selectedImageURL, setSelectedImageURL] = useState(null);
    const [selectedImageID, setSelectedImageID] = useState(null);
    const [selectedImageDefaultColor, setSelectedImageDefaultColor] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);

    const contextValue = {
        selectedBase64,
        selectedImageID,
        currentImage,
        setCurrentImage,
        setSelectedImageID,
        setSelectedBase64,
        selectedImageURL,
        setSelectedImageURL,
        selectedImageDefaultColor,
        setSelectedImageDefaultColor
    };

    return <ImageContext.Provider value={contextValue as any}>{children}</ImageContext.Provider>;
};

export default ImageContext;
