import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import CreateCustomLines from './components/CreateCustomLines';
import Feedback from './components/Feedback';
import SignIn from './components/GoogleSignIn/SignIn';
import { Landing } from './components/Landing';
import MobileMenu from './components/MobileMenu';
import Navbar from './components/Navbar';
import OrderCard from './components/OrderCard';
import Product from './components/Product';
import ProfilePage from './components/ProfilePage';
import ShoppingCart from './components/ShoppingCart';
import SideBar from './components/SideBar';
import ViewClothingLines from './components/ViewClothingLines';
import { CartProvider } from './contexts/CartContext';
import { ImageProvider } from './contexts/ImageContext';
import { ProductProvider } from './contexts/ProductsStore';
import { UserProvider } from './contexts/UserContext';
import './index.css';
import { useIPandFeedbackLogger } from './IPLogAndFeedbackBackend';
import { pathConfig } from 'models/sideBarModels';
import MobileNavBar from './components/MobileNavBar';
import OrderHistory from './components/OrderHistory';

function App() {
    const [isShowCart, setIsShowCart] = useState(false);
    const [isSideBarVisible, setIsSideBarVisible] = useState(false); // for mobile
    const [modError, setModError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [_isUserOnLogin, setIsUserOnLogin] = useState(true);
    const [isShowProduct, setIsShowProduct] = useState(false);
    const [isSettingsPage, setIsSettingsPage] = React.useState(false);

    const { handleClick, userIP, handleFeedbackLogClick } = useIPandFeedbackLogger();
    return (
        <Router>
            <UserProvider>
                <div className="font-montserrat">
                    <CartProvider>
                        <ImageProvider>
                            <ProductProvider>
                                <Routes>
                                    <Route
                                        path="/signIn"
                                        element={<SignIn userIP={userIP} setIsUserOnLogin={setIsUserOnLogin} />}
                                    />
                                    <Route
                                        path="/"
                                        element={
                                            <div>
                                                <SideBar />
                                                <div className="pb-20 sm:pb-0 sm:-mb-16">
                                                    <Navbar
                                                        setIsSideBarVisible={setIsSideBarVisible}
                                                        isSideBarVisible={isSideBarVisible}
                                                        setIsShowCart={setIsShowCart}
                                                        handleClick={(componentName, actionName, metadataImage) =>
                                                            handleClick(componentName, actionName, metadataImage)
                                                        }
                                                    />
                                                </div>
                                                {isSideBarVisible && (
                                                    <MobileMenu setIsSideBarVisible={setIsSideBarVisible} />
                                                )}
                                                <MobileNavBar isSettingsPage={isSettingsPage}/>
                                                <div
                                                    className={`flex flex-col 2xl:flex-row md:lg:pt-20 2xl:pt-20 pb-[4rem] ${
                                                        isSideBarVisible ? 'hidden' : ''
                                                    }`}
                                                >
                                                    <Landing
                                                        setSuccessMessage={setSuccessMessage}
                                                        setIsSettingsPage={setIsSettingsPage}
                                                        successMessage={successMessage}
                                                        modError={modError}
                                                        setModError={setModError}
                                                        handleClick={(componentName, actionName, metadataImage) =>
                                                            handleClick(componentName, actionName, metadataImage)
                                                        }
                                                    />
                                                    <Product
                                                        setSuccessMessage={setSuccessMessage}
                                                        setModError={setModError}
                                                        handleClick={(componentName, actionName, metadataImage) =>
                                                            handleClick(componentName, actionName, metadataImage)
                                                        }
                                                        isInTourMode={undefined}
                                                        isShowProduct={undefined}
                                                        setIsShowProduct={undefined}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                    {pathConfig.creatorCustomLines.enabled && (
                                        <Route
                                            path="/creator-custom-lines"
                                            element={
                                                <div>
                                                    <SideBar />
                                                    <div className="pb-20 sm:pb-0 sm:-mb-24">
                                                        <Navbar
                                                            setIsSideBarVisible={setIsSideBarVisible}
                                                            isSideBarVisible={isSideBarVisible}
                                                            setIsShowCart={setIsShowCart}
                                                            handleClick={(componentName, actionName, metadataImage) =>
                                                                handleClick(componentName, actionName, metadataImage)
                                                            }
                                                        />
                                                    </div>
                                                    {isSideBarVisible && (
                                                        <MobileMenu setIsSideBarVisible={setIsSideBarVisible} />
                                                    )}
                                                    <div className={`${isSideBarVisible ? 'hidden' : ''}`}>
                                                        <CreateCustomLines
                                                            handleClick={(componentName, actionName, metadataImage) =>
                                                                handleClick(componentName, actionName, metadataImage)
                                                            }
                                                        />
                                                    </div>
                                                    <MobileNavBar isSettingsPage={isSettingsPage}/>
                                                </div>
                                            }
                                        />
                                    )}
                                    {/* for users own profiles */}
                                    <Route
                                        path={'/profile/:user_ID'}
                                        element={
                                            <div className="">
                                                <div className="z-20">
                                                    <SideBar/>
                                                    <div className="pb-20 sm:pb-0 sm:-mb-24">
                                                        <Navbar
                                                            setIsSideBarVisible={setIsSideBarVisible}
                                                            isSideBarVisible={isSideBarVisible}
                                                            setIsShowCart={setIsShowCart}
                                                            handleClick={(componentName, actionName, metadataImage) =>
                                                                handleClick(componentName, actionName, metadataImage)
                                                            }
                                                        />
                                                    </div>
                                                    {isSideBarVisible && (
                                                        <MobileMenu setIsSideBarVisible={setIsSideBarVisible} />
                                                    )}
                                                    <MobileNavBar isSettingsPage={isSettingsPage} />
                                                    <div className={`${isSideBarVisible ? 'hidden' : ''}`}>
                                                        <ProfilePage
                                                            setIsSettingsPage={setIsSettingsPage}
                                                            isShowProduct={isShowProduct}
                                                            setIsShowProduct={setIsShowProduct}
                                                        />
                                                    </div>
                                                    {isShowProduct && (
                                                        <div className="absolute inset-0 flex items-center justify-center z-45">
                                                            <Product
                                                                isShowProduct={isShowProduct}
                                                                setIsShowProduct={setIsShowProduct}
                                                                setSuccessMessage={setSuccessMessage}
                                                                setModError={setModError}
                                                                handleClick={(
                                                                    componentName,
                                                                    actionName,
                                                                    metadataImage
                                                                ) =>
                                                                    handleClick(
                                                                        componentName,
                                                                        actionName,
                                                                        metadataImage
                                                                    )
                                                                }
                                                                isInTourMode={undefined}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    />
                                    <Route
                                        path="/explore"
                                        element={
                                            <div>
                                                <SideBar />
                                                <div className="pb-20 sm:pb-0 sm:-mb-24">
                                                    <Navbar
                                                        setIsSideBarVisible={setIsSideBarVisible}
                                                        isSideBarVisible={isSideBarVisible}
                                                        setIsShowCart={setIsShowCart}
                                                        handleClick={(componentName, actionName, metadataImage) =>
                                                            handleClick(componentName, actionName, metadataImage)
                                                        }
                                                    />
                                                </div>
                                                {isSideBarVisible && (
                                                    <MobileMenu setIsSideBarVisible={setIsSideBarVisible} />
                                                )}
                                                <div className={`${isSideBarVisible ? 'hidden' : ''}`}>
                                                    <ViewClothingLines setIsSettingsPage={setIsSettingsPage} />
                                                </div>
                                                <MobileNavBar isSettingsPage={isSettingsPage}/>
                                            </div>
                                        }
                                    />
                                    <Route
                                        path="/feedback"
                                        element={<Feedback handleFeedbackLogClick={handleFeedbackLogClick} />}
                                    />
                                    <Route 
                                        path={'/orders'} 
                                        element={
                                            <div>
                                                <SideBar />
                                                <div className="pb-20 sm:pb-0 sm:-mb-24">
                                                    <Navbar
                                                        setIsSideBarVisible={setIsSideBarVisible}
                                                        isSideBarVisible={isSideBarVisible}
                                                        setIsShowCart={setIsShowCart}
                                                        handleClick={(componentName, actionName, metadataImage) =>
                                                            handleClick(componentName, actionName, metadataImage)
                                                        }
                                                    />
                                                </div>
                                                {isSideBarVisible && (
                                                    <MobileMenu setIsSideBarVisible={setIsSideBarVisible} />
                                                )}
                                                <MobileNavBar isSettingsPage={isSettingsPage}/>
                                                <div className='pb-20 lg:pb-0 sm:py-28'>
                                                    <OrderHistory />
                                                </div>
                                            </div>
                                        } 
                                    />
                                    <Route 
                                        path={'/orders/:order_id'} 
                                        element={
                                            <div>
                                                <SideBar />
                                                <div className="pb-20 sm:pb-0 sm:-mb-24">
                                                    <Navbar
                                                        setIsSideBarVisible={setIsSideBarVisible}
                                                        isSideBarVisible={isSideBarVisible}
                                                        setIsShowCart={setIsShowCart}
                                                        handleClick={(componentName, actionName, metadataImage) =>
                                                            handleClick(componentName, actionName, metadataImage)
                                                        }
                                                    />
                                                </div>
                                                {isSideBarVisible && (
                                                    <MobileMenu setIsSideBarVisible={setIsSideBarVisible} />
                                                )}
                                                <MobileNavBar isSettingsPage={isSettingsPage}/>
                                                <div className='sm:pt-[17rem] pb-12 sm:pb-[14rem] lg:pt-5 lg:pb-0 '>

                                                    <OrderCard />
                                                </div>
                                            </div>
                                        } 
                                    />

                                </Routes>
                                {isShowCart && (
                                    <ShoppingCart
                                        handleClick={(componentName, actionName, metadataImage) =>
                                            handleClick(componentName, actionName, metadataImage)
                                        }
                                        setIsShowCart={setIsShowCart}
                                    />
                                )}
                            </ProductProvider>
                        </ImageProvider>
                    </CartProvider>
                </div>
            </UserProvider>
        </Router>
    );
}

export default App;
