import React from 'react';
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineWarning } from 'react-icons/ai';
import { BiErrorAlt } from 'react-icons/bi';

const ErrorPrompts = ({
    successMessage,
    errorMessage,
    noPromptMessage,
    onErrorClick,
    onMouseEnter,
    onMouseLeave
}) => {
    return (
        <div key={errorMessage || noPromptMessage || successMessage}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`${errorMessage ? 'text-red-600 border-red-600 shadow-red-400 animate-fadeOut' :
                noPromptMessage ? 'text-yellow-500 border-yellow-500 shadow-yellow-500 animate-fadeOut' :
                    successMessage ? 'text-lime-600 border-lime-500 shadow-lime-500 animate-fadeOut' : 'hidden'} font-bold flex 
                    items-center bg-white p-4 mb-4 text-sm border-2 shadow-inner rounded-lg fixed mx-auto z-50 w-11/12 sm:w-1/2 md:w-1/3 lg:w-1/4`}
            role="alert"
        >
            {errorMessage ? (
                <div className="flex justify-center">
                    <BiErrorAlt size={30} className="text-red-600 mr-[1rem]"/>
                    <span className={'mt-1'}>{errorMessage}</span>
                </div>
            ) : noPromptMessage ? (
                <div className="flex justify-center">
                    <AiOutlineWarning size={30} className="text-yellow-500 mr-[1rem]" />
                    <span className={'mt-1'}>{noPromptMessage}</span>
                </div>
            ) : (
                <div className="flex justify-center">
                    <AiFillCheckCircle size={30} className="text-lime-600 mr-[1rem]" />
                    <span className={'mt-1'}>{successMessage}</span>
                </div>
            )}
            <button
                onClick={onErrorClick}
                type="button"
                className={`${errorMessage ? 'text-red-500 hover:text-red-700' : noPromptMessage ? 'text-yellow-600 hover:text-yellow-700' : 'text-lime-600 hover:text-lime-700'} ml-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 inline-flex h-8 w-8`}
                data-dismiss-target="#alert-2"
                aria-label="Close"
            >
                <AiFillCloseCircle size={35} className={'mt-[-0.5rem]'}></AiFillCloseCircle>
            </button>
        </div>
    );
};

export default ErrorPrompts;
