import React, { useContext } from 'react';
import { CartContext } from '../contexts/CartContext';
import ProductOverlays from './ProductOverlays';

const SingleCartProduct = ({ priceId, product_id, quantity, size, color, 
    base64, imageId, handleClick, selectedProduct }) => {
    const cart = useContext(CartContext);
    const quantityIncreaseOnClick = () => {
        cart.addOneToCart(priceId, product_id, size, color, base64, imageId, selectedProduct.product_images);
        handleClick('ShoppingCartProducts', 'Quantity Increase', '');
    };

    const quantityDecreaseOnClick = () => {
        cart.removeOneFromCart(priceId, product_id, size, color, base64, imageId, selectedProduct.product_images);
        handleClick('ShoppingCartProducts', 'Quantity Decrease', '');
    };

    const deleteFromCartOnClick = () => {
        cart.deleteFromCart(priceId, product_id, size, color, base64, imageId, selectedProduct.product_images);
        handleClick('ShoppingCartProducts', 'Remove From Cart', '');
    };

    return (
        <div className="flex flex-col sm:md:lg:flex-row items-center w-full overflow-x-hidden overflow-y-hidden">
            <ProductOverlays color={color} product_id={selectedProduct.product_id} image_url={base64}/>
            <div className="w-full px-2 md:pl-3 md:w-3/4 pb-5">
                <div className="flex items-center justify-between w-full">
                    <p className="text-base font-black leading-none text-black">T Shirt</p>
                </div>
                <p className="text-xs leading-3 text-black pt-4">Color: {color}</p>
                <p className="w-96 text-xs leading-3 text-black py-4">Size: {size}</p>
                <p className="w-96 text-xs leading-3 text-black">Quantity: {quantity}</p>

                <button
                    className="my-1 px-1  bg-gray-800 border-gray-400 border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 text-white"
                    onClick={quantityIncreaseOnClick}
                >
                    +
                </button>
                <button
                    className={
                        quantity > 1
                            ? 'my-1 px-1.5  bg-gray-800 border-gray-400 border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 text-white'
                            : 'hidden'
                    }
                    onClick={quantityDecreaseOnClick}
                >
                    -
                </button>
                <div className="flex items-center justify-between pt-5 ">
                    <div className="flex ">
                        <p
                            className="text-xs leading-3 underline text-red-500 cursor-pointer"
                            onClick={deleteFromCartOnClick}
                        >
                            Remove
                        </p>
                    </div>

                    {/* <div className={'flex items-center justify-between gap-2'}>
                        <span className="text-base font-black leading-none text-red-500">${productData.price} (25% OFF)</span>
                        <span className="text-sm line-through text-black">${productData.presalePrice}</span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default SingleCartProduct;
