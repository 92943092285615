import React, { useContext, useState, useEffect, useCallback } from 'react';
import { BsCartPlusFill, BsFillBagCheckFill } from 'react-icons/bs';
import blackTShirt from '../images/black-500x500.png';
import { CartContext } from '../contexts/CartContext';
import ImageContext from '../contexts/ImageContext';
import SizeGuide from './SizeGuide';
import '../index.css';
import { UserContext } from '../contexts/UserContext';
import { apiGatewayClient } from './utils';
import { useGlobalProducts } from '../contexts/ProductsStore';

enum AvailableSizes {
    S = 'S',
    M = 'M',
    L = 'L',
    X = 'XL',
    XXL = 'XXL',
    XXXL = 'XXXL',
}

interface Product {
    product_id: string;
    name: string;
    description: string;
    timestamp: string;
    min_price: number;
    min_price_id: string;
    available_colors: string[];
  }

const Product = ({
    handleClick,
    isInTourMode,
    setModError,
    setSuccessMessage,
    isShowProduct,
    setIsShowProduct
}) => {
    const products = useGlobalProducts();
    const { selectedImageURL, selectedImageID, setSelectedImageURL, setSelectedImageID, 
        selectedImageDefaultColor, setSelectedImageDefaultColor } = useContext(ImageContext);
    const [selectedSize, setSelectedSize] = useState(AvailableSizes.L);
    const [selectedColor, setSelectedColor] = useState(products.length > 0 ? products[1].available_colors[1] : 'Black');
    const [selectedType, setSelectedType] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const cart = useContext(CartContext);
    const [buyNowCartItem, setBuyNowCartItem] = useState([]);
    const [showSizeGuide, setShowSizeGuide] = useState(false);
    const { user_ID } = useContext(UserContext);

    useEffect(() => {
        if(products.length > 0) {
            setSelectedProduct(products[1]);
            setSelectedType(products[1].product_id);
        }
    }, [products]);

    useEffect(() => {
        const imageINFO = JSON.parse(localStorage.getItem('imageINFO'));
        if (imageINFO) {
            setSelectedImageURL(imageINFO.image_url);
            setSelectedImageID(imageINFO.id);
        }
    }, [setSelectedImageID, setSelectedImageURL]);

    useEffect(() => {
        const handleUnload = () => {
            localStorage.removeItem('imageINFO');
        };

        window.addEventListener('beforeunload', handleUnload);

        // Clean up
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    const handleSizeMenuClick = () => {
        handleClick('Product', 'Sizing Menu', '');
    };
    const handleColorMenuClick = () => {
        handleClick('Product', 'Color Menu', '');
    };

    const handleAddToCartClick = () => {
        handleClick('Product', 'Add to Cart Button', '');
        if (selectedImageURL) {
            cart.addOneToCart(
                selectedProduct.price_id, selectedProduct.product_id, selectedSize, selectedColor, 
                selectedImageURL, selectedImageID, selectedProduct.product_images);
            setSuccessMessage('');
            setTimeout(() => setSuccessMessage('Item Successfully Added To Cart!'), 50);
            setModError(true);
        }
    };

    const handleBuyNow = async () => {
        handleClick('Product', 'Buy Now Button', '');
        console.log('selected product', selectedProduct);
        const buyNowItem = [
            {
                price_id: selectedProduct.price_id,
                product_id: selectedType,
                quantity: 1,
                color: selectedColor,
                size: selectedSize,
                image_id: selectedImageID
            }
        ];
        setBuyNowCartItem(buyNowItem);
    };

    const checkPaymentStatus = async sessionId => {
        await apiGatewayClient({
            path: 'checkout-success',
            method: 'POST',
            body: {
                session_id: sessionId
            }
        });
    };

    const handleCheckOutProcess = useCallback(async() => {
        await apiGatewayClient({
            path: 'checkout',
            method: 'POST',
            body: {
                user_id: user_ID,
                cart_items: buyNowCartItem
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(response => {
                if (response.checkout_url) {
                    window.location.assign(response.checkout_url); // forwarding user to stripe
                    window.addEventListener('beforeunload', () => checkPaymentStatus(response.id));
                }
            })
            .catch(e => {
                console.error('There was a problem with the fetch operation: ' + e.message);
            });
    }, [buyNowCartItem, user_ID]);

    useEffect(() => {
        if (buyNowCartItem && buyNowCartItem.length > 0) {
            handleCheckOutProcess();
        }
    }, [buyNowCartItem, handleCheckOutProcess]);

    const handleSizeGuideClick = () => {
        handleClick('Product', 'Size Guide Button', '');
        setShowSizeGuide(true);
    };

    const handleZoomIn = e => {
        e.currentTarget.style.transform = 'scale(2.5)';
    };

    const handleZoomOut = e => {
        e.currentTarget.style.transform = 'scale(1)';
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    const [zoomed, setZoomed] = useState(false);
    const mobileZoom = (e) => {
        if(windowWidth < 640) {
            if(zoomed === false) {
                setZoomed(true);
                e.currentTarget.style.transform = 'scale(2.5)';
            } else {
                setZoomed(false);
                e.currentTarget.style.transform = 'scale(1)';
            }
        }
    };

    const handleCloseProduct = () => {
        setIsShowProduct(false);
    };

    useEffect(() => {
        if(!selectedImageDefaultColor && products.length > 0){
            setSelectedColor(products ? products[1].available_colors[1] : 'Black');
        }
    }, [user_ID, products, selectedImageDefaultColor, setSelectedImageDefaultColor]);

    useEffect(() => {
        if (selectedImageDefaultColor) {
            setSelectedColor(selectedImageDefaultColor);
        } 
    }, [selectedImageDefaultColor]);

    return (
        <ImageContext.Provider value={null}>
            <div className="mt-[1.5rem]">
                {user_ID && (
                    <div className={`px-2 ${innerWidth > 1000 ? 'pr-5' : ''} pb-4 lg:pl-12 mb-4`}>
                        {showSizeGuide && (
                            <SizeGuide showSizeGuide={showSizeGuide} setShowSizeGuide={setShowSizeGuide} />
                        )}
                        <div className="product-page flex w-full relative bg-white md:w-[56rem] lg:w-[39.0625rem] md:lg:h-[51.2rem] rounded-lg flex flex-col justify-between items-center mx-auto z-35 border-[3px] shadow-xl shadow-gray-600 overflow-x-disabled sm:md:lg:overflow-x-hidden">
                            {isShowProduct && (
                                <button
                                    type="button"
                                    className="absolute right-2 top-2 p-2 hover:bg-gray-300 rounded-lg z-30"
                                    onClick={handleCloseProduct}
                                >
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            )}
                            <div className="flex justify-center relative z-35">
                                {selectedProduct && (
                                    <img
                                        className={`rounded-md mt-[0.5rem] md:lg:mt-[2.5rem] ${selectedType === products[1].product_id ? 'w-[100%]' : 'w-[95%]'}`}
                                        src={
                                            selectedProduct.product_images[selectedColor]}
                                        alt=""
                                    />
                                )}
                                {selectedImageURL && (
                                    <img
                                        className="absolute inset-0 m-auto top-[-4%] lg:top-[0%] right-[-2%] w-[40%] h-auto"
                                        style={{
                                            transition: 'transform .2s' // Add this line for transition (smoothness)
                                        }}
                                        src={selectedImageURL as any}
                                        alt=""
                                        onMouseEnter={handleZoomIn} // Add these lines for zoom effect
                                        onClick={mobileZoom}
                                        onMouseLeave={handleZoomOut} // on hover
                                    />
                                )}
                                {!selectedImageURL && (
                                    <div
                                        className={`absolute inset-0 m-auto border ${
                                            selectedColor === 'Black' ? 'text-white' : 'text-black border-black'
                                        } flex justify-center items-center top-[-4%] lg:top-[0%] w-[40%] h-[40%]`}
                                    >
                                        <p className="text-center text-xl font-bold">Please Generate Image</p>
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center justify-between gap-8 mb-4">
                                <div>
                                    <h5 className="sm:block text-2xl font-bold tracking-tight text-black">
                                    T-Shirt
                                    </h5>
                                </div>
                                <div className={'flex items-center justify-center gap-2'}>
                                    <span className="text-lg font-black leading-none text-red-500">${selectedProduct ? selectedProduct.presalePrice : ''} (25% OFF)</span>
                                    <span className="text-md line-through text-black">${selectedProduct ? selectedProduct.price : ''}</span>
                                </div>
                            </div>
                            <div className="md:lg:flex md:lg:space-x-8 px-2 pb-2">
                                <div className="flex flex-col w-70">
                                    <div>
                                        <button
                                            className={`text-black text-md border-b-2 border-gray-600 hover:border-gray-400 hover:text-gray-400 font-bold ${
                                                isInTourMode ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                            onClick={handleSizeGuideClick}
                                            disabled={isInTourMode}
                                        >
                                            Size Guide
                                        </button>
                                    </div>
                                    <div className="mt-2">
                                        <div className="flex justify-center items-center gap-7">
                                            <div className='flex flex-col'>
                                                <div className="flex flex-col">
                                                    <p className="text-black font-bold text-sm">Select Sizing</p>
                                                    <select
                                                        onClick={handleSizeMenuClick}
                                                        defaultValue={selectedSize}
                                                        onChange={event =>
                                                            setSelectedSize(event.target.value as AvailableSizes)}
                                                        className={`w-26 p-2.5 mb-2 text-gray-500 bg-white border rounded-md shadow-md shadow-gray-800 outline-none appearance-none focus:border-grey-900 font-bold ${
                                                            isInTourMode ? 'opacity-50 cursor-not-allowed' : ''
                                                        }`}
                                                        disabled={isInTourMode}
                                                    >
                                                        {Object.values(AvailableSizes).map((value, index) =>
                                                            (<option key={index} className="font-bold" value={value}>{value}</option>))}
                                                    </select>
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="text-black font-bold text-sm">Select Color</p>
                                                    <select
                                                        onClick={handleColorMenuClick}
                                                        value={selectedColor}
                                                        onChange={event =>
                                                            setSelectedColor(event.target.value)}
                                                        className={'w-26 p-2.5 mb-2 text-gray-500 bg-white border rounded-md shadow-md shadow-gray-800 outline-none appearance-none focus:border-grey-900 font-bold'}
                                                    >
                                                        {selectedProduct && 
                                                        selectedProduct.available_colors.map((color, index) => (
                                                            <option key={index} className="font-bold" value={color}>{color}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex flex-col">
                                                <p className="text-black font-bold text-sm">Select Type</p>
                                                <select
                                                    onClick={handleColorMenuClick}
                                                    onChange={event => {
                                                        const selectedProductId = event.target.value;
                                                        // Find the product in the products array
                                                        const product = 
                                                        products.find(p => p.product_id === selectedProductId);
                                                        if (product) {
                                                            setSelectedProduct(product);
                                                            setSelectedType(product.product_id);
                                                        }}}
                                                    className={'w-26 p-2.5 mb-2 text-gray-500 bg-white border rounded-md shadow-md shadow-gray-800 outline-none appearance-none focus:border-grey-900 font-bold'}
                                                >
                                                    {products.slice().reverse().map((product, index) => (
                                                        <option key={index} className="font-bold" value={product.product_id}>{product.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center space-x-8 md:lg:flex-col md:lg:space-x-0 items-center">
                                    <button
                                        type="submit"
                                        className={`w-26 text-white border border-gray-700 font-bold rounded-lg text-md px-4 py-2 mt-2 shadow-md shadow-gray-700 bg-neutral-900 hover:bg-neutral-500  ${
                                            isInTourMode ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                        onClick={handleAddToCartClick}
                                    >
                                        <div className="flex justify-center items-center">
                                            <BsCartPlusFill className={'text-blue-500'} size={20}></BsCartPlusFill><span className="ml-2">Add to Cart</span>
                                        </div>
                                    </button>
                                    <button
                                        type="submit"
                                        className={`w-26 text-white border border-gray-700 font-bold rounded-lg text-md px-6 py-2 mt-2 shadow-md shadow-gray-900 bg-neutral-900 hover:bg-neutral-700 ${
                                            isInTourMode ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                        onClick={handleBuyNow}
                                    >
                                        <div className="flex justify-center items-center">
                                            <BsFillBagCheckFill className={'text-green-500'} size={20}></BsFillBagCheckFill><span className="ml-2">Buy Now</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ImageContext.Provider>
    );
};

export default Product;
