export interface PathDetails {
    path: string;
    enabled: boolean;
}

export interface AllowedPaths {
    feedback: string;
    home: string;
    creatorCustomLines: string;
    explore: string;
    signIn: string;
    profile: string;
    orderData: string;
}

export const pathConfig: Record<keyof AllowedPaths, PathDetails> = {
    feedback: { path: '/feedback', enabled: true },
    home: { path: '/home', enabled: true },
    creatorCustomLines: { path: '/creator-custom-lines', enabled: false },
    explore: { path: '/explore', enabled: true },
    signIn: { path: '/signIn', enabled: true },
    profile: { path: '/profile', enabled: true },
    orderData: { path: '/order-data', enabled: false }
};
