import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { UserContext } from '../contexts/UserContext';
import ErrorPrompts from './ErrorPrompts';
import { apiGatewayClient, createGetImagePayload } from './utils';

const CreateCustomLines = ({ handleClick }) => {
    // CURRENTLY NOT USING DATETIME IN GET_IMAGE_HISTORY ENDPOINT
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBase64, setSelectedBase64] = useState();
    const [createdBY, setCreatedBY] = useState();
    const [selectedImageSEED, setSelectedImageSEED] = useState();
    const [modelName, setModelName] = useState('');
    const [selectedTrainingIMGIDs, setSelectedTrainingIMGIDs] = useState([]);
    const [selectedTrainingIMGs, setSelectedTrainingIMGs] = useState([]);
    const [TrainedModelParams, setTrainedModelParams] = useState({
        subject: '',
        setting: '',
        art_style: '',
        color_palette: '',
        time_period: '',
        emotion: ''
    });
    const { user_ID } = useContext(UserContext);

    const [modelCreationImages, setModelCreationImages] = useState([]);
    const [isModelCreationLoading, setIsModelCreationLoading] = useState(false);
    const [isModelCreated, setIsModelCreated] = useState(false);
    const [isInfoHovered, setIsInfoHovered] = useState(false);
    const [isSmartSearchImageLoading, setIsSmartSearchImageLoading] = useState(false);
    const [handleBGToggle, setHandleBGToggle] = useState(false);

    const handleMouseEnterINFO = () => {
        setIsInfoHovered(true);
    };

    const handleMouseLeaveINFO = () => {
        setIsInfoHovered(false);
    };

    // for moderation error
    const [modError, setModError] = useState(false);
    const [successMessage, setSuccessMessage] = useState();
    const [noPromptMessage, setNoPromptMessage] = useState();
    const [hovered, setHovered] = useState(false);
    const timerRef = useRef();

    const onErrorClick = () => {
        setModError(false);
        handleClick('Landing', 'Error Card Button', '');
    };

    const handleMouseEnter = () => {
        setHovered(true);
        clearTimeout(timerRef.current);
    };

    const handleMouseLeave = () => {
        setHovered(false);
        timerRef.current = setTimeout(() => {
            setModError(false);
            setSuccessMessage(null);
            setNoPromptMessage(null);
        }, 5000) as any;
    };

    const showErrorPrompt = (succesMessage, noPromptMessage) => {
        setSuccessMessage(succesMessage);
        setNoPromptMessage(noPromptMessage);
        setModError(true);

        timerRef.current = setTimeout(() => {
            if (!hovered) {
                setModError(false);
                setSuccessMessage(null);
                setNoPromptMessage(null);
            }
        }, 5000) as any;
    };
    const fetchCredits = async () => {
        try {
            const response = await apiGatewayClient({
                path: 'get_available_credits',
                method: 'GET',
                queryParams: {
                    user_id: user_ID
                }
            });
            const data = await response.json();
            setAvailableCredits(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchCredits();
    }, [user_ID]);

    // eslint-disable-next-line
    const [hasError, setHasError] = useState(false);

    const [searchText, setSearchText] = useState('');

    const handleModelNameText = e => {
        setModelName(e.target.value);
    };

    const handleSearch = async event => {
        event.preventDefault();
        handleClick('Landing', 'Generate Button', '');
        if (availableCredits <= 0) {
            setModError(true);
            setNoPromptMessage('You do not have enough credits to generate an image.' as any);
        } else {
            setIsSmartSearchImageLoading(true);
            setImages([{ isLoading: true }, ...images]);

            const seed = null;
            const model_id = null;
            const Enhanced = true;
            const getImagePayload = createGetImagePayload(user_ID, searchText, seed, model_id, Enhanced, 
                handleBGToggle);
            const response = await apiGatewayClient({
                path: 'get_images',
                method: 'POST',
                body: getImagePayload
            });
            const responseData = await response.json();

            if (Array.isArray(responseData) && !responseData[0].no_prompt_error) {
                setHasError(false);
                const imageINFO = responseData.map(imgDict => ({
                    image_url: imgDict.image_url,
                    id: imgDict.image_id
                }));

                if (!hasError) {
                    setImages(prevState => [...imageINFO, ...prevState.slice(1)]);
                }
                setData([...imageINFO, ...data]);
                setIsSmartSearchImageLoading(false); // Set isLoading to false once data is fetched
                fetchCredits();
            } else {
                setHasError(true);
                setIsSmartSearchImageLoading(false); // Set isLoading to false if there is an error
                showErrorPrompt(responseData['error'], responseData['no_prompt_error']);
            }
        }
    };

    const handleImageClick = image => {
        setSelectedBase64(image.base64);
        setSelectedImageSEED(image.seed);
        setCreatedBY(image.metadata.created_by);
    };

    const handleTrainModelClick = async () => {
        setIsModelCreationLoading(true);
        const response = await apiGatewayClient({
            path: 'create_user_model',
            method: 'POST',
            body: {
                user_id: user_ID,
                image_ids: selectedTrainingIMGIDs
            }
        });
        const responseData = await response.json();
        if (Array.isArray(responseData) && responseData.every(img => img.base64)) {
            setModelCreationImages(responseData);
            setIsModelCreationLoading(false);
            setIsModelCreated(true);
        }
        setModError(true);
        setSuccessMessage('Model Has Been Trained!' as any);
    };

    const handleTrainingImageClick = imageINFO => {
        if (selectedTrainingIMGs.find(img => img.id === imageINFO.id)) {
            setSelectedTrainingIMGs(selectedTrainingIMGs.filter(img => img.id !== imageINFO.id));
            setSelectedTrainingIMGIDs(prevIDs =>
                prevIDs.filter(
                    ids =>
                        // This assumes that the parameters object is structured in a way that makes it uniquely identifiable by its properties
                        // If not, you might have to find another way to uniquely identify each parameter set (e.g., by associating each with an ID)
                        JSON.stringify(ids) !== JSON.stringify(imageINFO.id)
                )
            );
        } else {
            setSelectedTrainingIMGs([...selectedTrainingIMGs, imageINFO]);
            setSelectedTrainingIMGIDs(prevIDs => [...prevIDs, imageINFO.id]);
        }
    };

    const debounceTimeout = useRef(null);

    useEffect(() => {
        if (selectedTrainingIMGs.length >= 2) {
            // Clear the previous timeout (if it exists) to reset the debounce timer
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            // Set the new timeout
            debounceTimeout.current = setTimeout(() => {
                handleTrainModelClick();
            }, 2000); // 2000ms or 2 seconds
        }
        // Optional: Clear the timeout when the component is unmounted to prevent memory leaks
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [selectedTrainingIMGs]);

    const handleSaveModelClick = async () => {
        if (selectedImageSEED) {
            const response = await apiGatewayClient({
                path: 'save_user_model',
                method: 'POST',
                body: {
                    user_id: user_ID,
                    model_name: modelName,
                    seed: selectedImageSEED,
                    params: TrainedModelParams
                }
            });
            const responseData = await response.json();
            setModError(true);
            setSuccessMessage(responseData.message);
            await fetchCredits();
        } else {
            console.info('No current image');
        }
    };

    const [images, setImages] = useState([]);
    const scrollDiv = useRef(null);

    const getIMGHistory = async () => {
        if (user_ID) {
            setIsLoading(true);
            try {
                const response = await apiGatewayClient({
                    path: 'get_potential_model_training_images',
                    method: 'POST',
                    body: {
                        user_id: user_ID,
                        current_num_photos: images.length
                    }
                });
                const data = await response.json();
                const imageINFO = data.map(imgDict => ({
                    image_url: imgDict.image_url,
                    id: imgDict.image_id,
                    art_style: imgDict.params.art_style,
                    color_palette: imgDict.params.color_palette,
                    emotion: imgDict.params.emotion,
                    setting: imgDict.params.setting,
                    subject: imgDict.params.subject,
                    time_period: imgDict.params.time_period
                }));
                setImages(prevState => {
                    const newState = [...prevState];
                    imageINFO.forEach(image => {
                        if (!newState.some(prevImage => prevImage.id === image.id)) {
                            newState.push(image); // use unshift instead of push
                        }
                    });
                    return newState;
                });
                setIsLoading(false);
                setModelCreationImages(0 as any);
                setIsModelCreated(false);
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false);
            }
        }
    };

    const handleScroll = useCallback(
        e => {
            if (window.innerWidth < 1920) {
                // Checks if it's a small (mobile) screen
                // Check for reaching the right end for horizontal scrolling
                const threshold = 5;
                const rightEnd = e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth + threshold;
                if (rightEnd) {
                    getIMGHistory();
                    setIsLoading(false);
                }
            } else {
                // Check for reaching the bottom for vertical scrolling
                const threshold = 5;
                const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + threshold;
                if (bottom) {
                    getIMGHistory();
                    setIsLoading(false);
                }
            }
        },
        [getIMGHistory]
    );

    useEffect(() => {
        const div = scrollDiv.current;
        if (div) {
            div.addEventListener('scroll', handleScroll);
            // Remove event listener on cleanup
            return () => {
                div.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);

    useEffect(() => {
        getIMGHistory();
    }, [user_ID]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    // Assign a grid column class based on window width
    const layoutClass = windowWidth < 1920 ? 'grid grid-flow-col' : 'grid grid-cols-3';

    const [availableCredits, setAvailableCredits] = useState(0);

    return (
        <div className="relative bg-lightPrimary">
            <div className="flex justify-end mr-3">
                {modError && (
                    <ErrorPrompts
                        successMessage={successMessage}
                        noPromptMessage={noPromptMessage}
                        onErrorClick={onErrorClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        errorMessage={undefined}
                    />
                )}
            </div>
            <div className="flex flex-col lg:flex-row justify-center sm:md:lg:ml-28 items-center space-x-4 h-full lg:h-screen">
                <div className="px-2 rounded-lg w-full sm:w-2/3 xl:w-[60%] lg:w-[50%]">
                    <div className="m-2">
                        <p className="text-2xl font-bold underline pb-2">Model Training</p>
                        <div className="flex font-bold text-center px-2 text-sm items-center md:lg:mr-6 md:lg:px-0 md:lg:text-base text-lightSmallerText">
                            Credits Available: {availableCredits}
                        </div>
                    </div>
                    <div className="border rounded-lg mb-8">
                        <div className="flex items-center rounded-r-lg rounded-b-lg template-search">
                            <div className="flex w-full py-4 pl-4 overflow-x-disabled sm:md:lg:overflow-x-hidden">
                                <div className="flex w-full min-w-max sm:md:lg:min-w-0 scrollbar-div">
                                    <div className="relative w-full">
                                        <textarea
                                            maxLength={220}
                                            id={'Subject'}
                                            autoComplete="off"
                                            key={'Subject'}
                                            name={'Subject'}
                                            rows={3}
                                            className="peer placeholder-transparent overflow-hidden resize-none h-full w-full p-4 rounded-lg border border-gray-600 text-lightTitleText"
                                            placeholder="Subject"
                                            onChange={event => setSearchText(event.target.value)}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter' && !isLoading) {
                                                    event.preventDefault();
                                                    handleSearch(event);
                                                }
                                            }}
                                        />
                                        <label
                                            htmlFor={'subject'}
                                            className="absolute text-sm text-lightSmallerText  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[1.3rem] peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                                        >
                                            Enter a prompt to generate an image
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center mt-16 px-4">
                                    <button
                                        onClick={handleSearch}
                                        type="submit"
                                        // Disable the button while loading
                                        className={` text-white border border-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-3 bg-lightPrimaryButton hover:bg-lightPrimaryButtonHover focus:ring-[#0369a1] ${
                                            isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                    >
                                        Generate
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div
                            ref={scrollDiv}
                            className={`${layoutClass} gap-2 py-1 ${
                                images.length > 3
                                    ? `${windowWidth < 1920 ? 'h-[24.15rem] sm:h-[19.15rem]' : 'sm:h-[35.3rem]'}`
                                    : 'h-[19.15rem]'
                            } overflow-y-auto w-full sm:w-[86%] rounded-lg  scrollbar-div z-20 overflow-x-auto display-images-div`}
                        >
                            {images.map((imageINFO, index) => (
                                <div key={imageINFO?.id || index} className="px-2 w-[23rem] h-full sm:w-[18.5rem]">
                                    {isSmartSearchImageLoading && index === 0 ? (
                                        <div role="status" className="flex justify-center items-center h-full w-full">
                                            <svg
                                                aria-hidden="true"
                                                className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        <img
                                            onClick={() => handleTrainingImageClick(imageINFO)}
                                            alt="selectedImage"
                                            className={`${
                                                selectedTrainingIMGs.find(img => img.id === imageINFO.id)
                                                    ? 'border-4 border-lightImageHighlight'
                                                    : 'border'
                                            } rounded-md cursor-pointer h-auto w-full object-cover bg-lightImageBGHover bg-opacity-0 hover:opacity-60`}
                                            src={imageINFO.image_url}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div
                    id="SelectedModelImages"
                    className="border rounded-lg relative  mb-2 sm:mb-0 2xl:mt-[4.8rem] my-4 lg:my-0 "
                >
                    <div className="m-2">
                        <div className="flex justify-between">
                            <p className="text-2xl font-bold underline pb-2 text-lightTitleText">Model Creation</p>
                            <button
                                className=" w-8 flex justify-center items-center hover:bg-gray-700 my-1 rounded-md"
                                onMouseEnter={handleMouseEnterINFO}
                                onMouseLeave={handleMouseLeaveINFO}
                            >
                                <BsInfoCircle className={`${isInfoHovered ? 'text-white' : ''}`} size={20} />
                            </button>
                            {isInfoHovered && (
                                <div className="bg-black bg-opacity-60 absolute right-20 sm:right-24 sm:-top-28 p-2 rounded-md z-20">
                                    <p className="text-white">
                                        Once you train a model, you can choose one of the images that most suits your
                                        liking! Once selected please input a model name and save it!
                                    </p>
                                </div>
                            )}
                        </div>

                        <div className="border rounded-lg w-[24rem] h-[26rem] 2xl:w-[31rem] 2xl:h-[31rem] mt-2 flex justify-center items-center">
                            {isModelCreationLoading ? (
                                <div role="status" className="flex justify-center items-center h-full w-full">
                                    <svg
                                        aria-hidden="true"
                                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : isModelCreated && selectedTrainingIMGs.length > 1 ? (
                                <div
                                    className={`grid grid-flow-col gap-2 overflow-x-auto
                      h-[20.7rem] sm:h-full
                    } py-1 w-full rounded-lg scrollbar-div z-20 `}
                                >
                                    {Array.isArray(modelCreationImages) &&
                                        modelCreationImages.map((imageINFO, index) => (
                                            <div
                                                key={`${imageINFO?.id || index}`}
                                                className="w-[20.5rem] sm:md:lg:w-[30rem] flex relative overflow-y-hidden"
                                            >
                                                <img
                                                    alt="selectedImage"
                                                    onClick={() => handleImageClick(imageINFO)}
                                                    className={`rounded-md cursor-pointer h-auto object-cover bg-lightImageBGHover bg-opacity-0 hover:opacity-60
                            ${selectedBase64 === imageINFO.base64 ? 'border-4 border-lightImageHighlight' : 'border'}`}
                                                    src={`data:image/jpeg;base64,${imageINFO.base64}`}
                                                />
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <p className="text-lightTitleText">Please Train A Model And Generate</p>
                            )}
                        </div>
                        <div className="flex justify-center mt-2">
                            <div
                                className={`flex space-x-2 ${
                                    selectedTrainingIMGs.length > 5
                                        ? 'w-[19.6rem]  overflow-x-auto'
                                        : 'w-full  justify-center'
                                } scrollbar-div`}
                            >
                                {selectedTrainingIMGs.map((imageINFO, index) => (
                                    <div
                                        key={imageINFO?.id || index}
                                        className={`flex-shrink-0 flex ${
                                            selectedTrainingIMGs.length > 5 ? '' : 'items-center'
                                        }`}
                                    >
                                        <button
                                            onClick={() => handleTrainingImageClick(imageINFO)}
                                            className="relative group "
                                        >
                                            <img
                                                src={imageINFO.image_url}
                                                className="w-14 rounded-md group-hover:opacity-40"
                                            />
                                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black opacity-0 group-hover:opacity-100">
                                                <svg
                                                    className="w-5 h-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </div>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col mt-2">
                            <div className="flex space-x-2 h-12">
                                <div className="relative w-full ">
                                    <input
                                        maxLength={48}
                                        id={'ModelName'}
                                        autoComplete="off"
                                        key={'ModelName'}
                                        name={'ModelName'}
                                        value={modelName}
                                        className="peer placeholder-transparent overflow-hidden resize-none h-full w-full p-4 rounded-lg border border-gray-600 text-lightTitleText"
                                        placeholder="Model Name"
                                        onChange={handleModelNameText}
                                    />
                                    <label
                                        htmlFor={'modelName'}
                                        className="absolute text-sm text-lightSmallerText  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[1.5rem] peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                                    >
                                        Model Name
                                    </label>
                                </div>
                                <button
                                    type="submit"
                                    // Disable the button while loading
                                    disabled={!modelName}
                                    onClick={handleSaveModelClick}
                                    className={`${
                                        !modelName ? 'opacity-50 cursor-not-allowed' : ''
                                    } flex items-center text-white border border-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-3 bg-lightPrimaryButton hover:bg-lightPrimaryButtonHover focus:ring-[#0369a1] `}
                                >
                                    Save Model
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCustomLines;
