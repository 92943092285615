import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { CgProfile } from 'react-icons/cg';
import { AiFillHome } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { auth } from './GoogleSignIn/config';
import { GoSignOut, GoSignIn } from 'react-icons/go';
import { apiGatewayClient } from './utils';

const MobileMenu = ({ setIsSideBarVisible }) => {
    const { user_ID, userIP, realDisplayName, setFirebaseUID } = useContext(UserContext);
    const [isSignedOut, setIsSignedOut] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    const [displayIMG, setDisplayIMG] = useState();

    const getDisplayIMG = async () => {
        if (user_ID) {
            try {
                const response = await apiGatewayClient({
                    path: 'get_user_display_picture',
                    method: 'GET',
                    queryParams: {
                        user_id: user_ID
                    }
                });
                const data = await response.json();
                if (data) {
                    const imageINFO = {
                        image_url: data.image_url,
                        id: data.image_id
                    };
                    setDisplayIMG(imageINFO.image_url);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleSignOut = () => {
        auth.signOut()
            .then(() => {
                // Sign-out successful.
                localStorage.clear();
                sessionStorage.clear();
                setIsSignedOut(true);
                setFirebaseUID(null);
                navigate('/');
            })
            .catch(error => {
                console.error('Error', error);
            });
    };
    const handleSignIn = () => {
        navigate('/signIn'); // Redirect to login page after sign out
    };

    useEffect(() => {
        if (user_ID === userIP) {
            setIsSignedOut(true);
            setIsDisabled(true);
        } else {
            setIsSignedOut(false);
            setIsDisabled(false);
            getDisplayIMG();
        }
    }, [user_ID]);

    return (
        <div className="z-49 bg-white flex justify-center items-center h-[75vh] w-screen sm:md:lg:hidden">
            <div className="text-lg">
                <ul className="space-y-4 tracking-wide">
                    <li className="min-w-max">
                        <button
                            onClick={() => (setIsSideBarVisible(false), navigate('/'))}
                            className="group flex items-center space-x-5 pl-4 pr-[8.5rem] py-3 text-black hover:bg-[#f3f4f6] hover:text-[#0284c7]"
                        >
                            <AiFillHome size={20} />
                            <span className="group-hover:text-[#0284c7]">Home</span>
                        </button>
                    </li>
                    <li className="min-w-max">
                        {!isDisabled && (
                            <button
                                onClick={() => (setIsSideBarVisible(false), navigate(`/profile/${realDisplayName}`))}
                                className="group flex items-center space-x-5 pl-4 pr-[8.5rem] py-3 text-black hover:bg-[#f3f4f6] hover:text-[#0284c7]"
                            >
                                {displayIMG ? (
                                    <img
                                        src={displayIMG}
                                        alt="User Display Image"
                                        className="w-6 h-6 -ml-[0.1rem] rounded-full"
                                    />
                                ) : (
                                    <CgProfile size={20} />
                                )}
                                <span className="group-hover:text-[#0284c7]">Profile</span>
                            </button>
                        )}
                    </li>
                    <li className="min-w-max">
                        {!isDisabled && (
                            <button
                                onClick={() => (setIsSideBarVisible(false), navigate('/creator-custom-lines'))}
                                className="group flex items-center space-x-5 pl-4 pr-8 py-3 text-black hover:bg-[#f3f4f6]"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        className="fill-current text-black group-hover:text-cyan-300"
                                        fillRule="evenodd"
                                        d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
                                        clipRule="evenodd"
                                    />
                                    <path
                                        className="fill-current text-black group-hover:text-cyan-600"
                                        d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"
                                    />
                                </svg>
                                <span className="group-hover:text-[#0284c7]">Create Custom Lines</span>
                            </button>
                        )}
                    </li>
                    <li className="min-w-max">
                        <button
                            onClick={() => (setIsSideBarVisible(false), navigate('/explore'))}
                            className="group flex items-center space-x-5  pl-4 pr-32 py-3 text-black hover:bg-[#f3f4f6]"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    className="fill-current text-black group-hover:text-cyan-600"
                                    fillRule="evenodd"
                                    d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                                    clipRule="evenodd"
                                />
                                <path
                                    className="fill-current text-black group-hover:text-cyan-300"
                                    d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"
                                />
                            </svg>
                            <span className="group-hover:text-[#0284c7]">Explore</span>
                        </button>
                    </li>
                    <li className="min-w-max">
                        {!isDisabled && (
                            <button
                                className="group flex items-center space-x-5  pl-4 pr-24 py-3 text-black hover:bg-[#f3f4f6]"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        className="fill-current text-black group-hover:text-cyan-600"
                                        d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                                    />
                                    <path
                                        className="fill-current text-black group-hover:text-cyan-300"
                                        d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"
                                    />
                                </svg>
                                <span className="group-hover:text-[#0284c7]">Order Data</span>
                            </button>
                        )}
                    </li>
                    {isSignedOut ? (
                        <li className="min-w-max">
                            <button
                                onClick={handleSignIn}
                                className={'group flex items-center space-x-5  pl-4 pr-24 py-3 text-black hover:bg-[#f3f4f6]'}
                            >
                                <GoSignIn className="group-hover:text-[#0284c7]" />
                                <span className="mr-2 group-hover:text-[#0284c7]">Sign In</span>
                            </button>
                        </li>
                    ) : (
                        <li className="min-w-max">
                            <button
                                onClick={handleSignOut}
                                className={'group flex items-center space-x-5  pl-4 pr-32 py-3 text-black hover:bg-[#f3f4f6]'}
                            >
                                <GoSignOut className="group-hover:text-[#0284c7]" />
                                <span className="mr-2 group-hover:text-[#0284c7]">Sign Out</span>
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default MobileMenu;
