import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { UserContext } from '../contexts/UserContext';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { AiOutlineSearch } from 'react-icons/ai';
import { apiGatewayClient } from './utils';

const ViewClothingLines = ({ setIsSettingsPage }) => {
    const [exploreIMGs, setExploreIMGs] = useState([]);
    const [isExploreIMGsLoading, setIsExploreImgsLoading] = useState(false);
    const scrollDiv = useRef(null);
    const { user_ID } = useContext(UserContext);
    const [userSearch, setUserSearch] = useState('');
    const [listOfUsers, setListOfUsers] = useState([]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [hasScrolled, setHasScrolled] = useState(false);
    const scrollRef = useRef(null);
    const [listOfUsersDisplayPictures, setListOfUsersDisplayPictures] = useState([]);

    const getExploreImages = async () => {
        try {
            setIsExploreImgsLoading(true);
            const response = await apiGatewayClient({
                path: 'get_explore_images',
                method: 'GET',
                queryParams: {
                    user_id: user_ID,
                    current_num_photos: exploreIMGs.length
                }
            });
            const data = await response.json();
            const imageINFO = data.map(imgDict => ({
                image_url: imgDict.image_url,
                image_id: imgDict.image_id,
                user_id: imgDict.user_id
            }));
            setExploreIMGs(prevState => {
                const newState = [...prevState];
                imageINFO.forEach(image => {
                    if (!newState.some(prevImage => prevImage.image_id === image.image_id)) {
                        newState.push(image); // use unshift instead of push
                    }
                });
                return newState;
            });
            setIsExploreImgsLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setIsExploreImgsLoading(false);
        }
    };

    useEffect(() => {
        getExploreImages();
        setIsSettingsPage(false);
    }, [user_ID]);

    const handleScroll = useCallback(
        e => {
            // Check for reaching the bottom for vertical scrolling
            const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
            if (bottom) {
                getExploreImages();
                setIsExploreImgsLoading(false);
            }
        },
        [getExploreImages]
    );

    useEffect(() => {
        const div = scrollDiv.current;
        if (div) {
            div.addEventListener('scroll', handleScroll);
            // Remove event listener on cleanup
            return () => {
                div.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    // Assign a grid column class based on window width
    const layoutClass = windowWidth < 640 ? 'grid grid-flow-row' : 'grid grid-cols-3';

    const [currentImage, setCurrentImage] = useState();
    const [clickedImageUserID, setClickedImageUserID] = useState(null);
    const [clickedImageUsername, setClickedImageUsername] = useState();
    const [clickedImageUserProfilePic, setClickedImageUserProfilePic] = useState();
    const [clickedImageModal, setClickedImageModal] = useState(false);

    const handleImageClick = imageINFO => {
        setClickedImageModal(true);
        setCurrentImage(imageINFO.image_url);
        setClickedImageUserID(imageINFO.user_id);
    };

    const handleUsernameFetch = async () => {
        const response = await apiGatewayClient({
            path: 'search_explore_users',
            method: 'GET',
            queryParams: {
                user_id: user_ID,
                search_term: userSearch,
                current_num_users: listOfUsers.length
            }
        });
        const data = await response.json();

        setListOfUsers(prevUsers => [...prevUsers, ...data]);

        const displayImages = [];

        for (const user of data) {
            const response = await apiGatewayClient({
                path: 'get_user_display_picture',
                method: 'GET',
                queryParams: {
                    user_id: user.user_id
                }
            });
            const displayImageData = await response.json();
            displayImages.push(displayImageData.image_url);
        }

        // Merge the existing listOfUsersDisplayPictures with the new displayImages
        setListOfUsersDisplayPictures(prevDisplayPictures => [...prevDisplayPictures, ...displayImages]);
    };

    useEffect(() => {
        // Clear the listOfUsers and listOfUsersDisplayPictures when userSearch changes
        setListOfUsers([]);
        setListOfUsersDisplayPictures([]);
    
        // If there's an existing debounce timer, clear it
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
    
        // Set up a new timer
        const timer = setTimeout(() => {
            if (userSearch) {
                handleUsernameFetch();
            }
        }, 300); // You can adjust the debounce time as needed
    
        // Save the timer ID to the state so we can clear it later
        setDebounceTimeout(timer);
    
        // Clear the timer on component unmount or if userSearch changes before 300ms
        return () => {
            clearTimeout(timer);
        };
    }, [userSearch]);
    

    useEffect(() => {
        const db = getDatabase();
        const usersRef = ref(db, 'users');
        onValue(usersRef, async snapshot => {
            const users = snapshot.val();
            let userExists = false;
            let existingUsername = null;

            // Check if user with the email already exists
            for (const key in users) {
                if (users[key].UID === clickedImageUserID) {
                    userExists = true;
                    existingUsername = key;
                    break;
                }
            }
            if (userExists) {
                setClickedImageUsername(existingUsername);
                const response = await apiGatewayClient({
                    path: 'get_user_display_picture',
                    method: 'GET',
                    queryParams: {
                        user_id: clickedImageUserID
                    }
                });
                const data = await response.json();
                if (data) {
                    setClickedImageUserProfilePic(data.image_url);
                }
            } else {
                setClickedImageUsername('N/A' as any);
                setClickedImageUserProfilePic(null);
            }
        });
    }, [currentImage]);

    const handleCloseModal = async () => {
        setClickedImageModal(false);
    };

    const navigate = useNavigate();
    const handleUsernameClick = username => {
        navigate(`/profile/${username}`);
    };

    const handleUserSearchBarClick = username_ID => {
        navigate(`/profile/${username_ID}`);
    };

    const handleUserNameScroll = event => {
        if (!hasScrolled) {
            setHasScrolled(true);
        }

        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollHeight - scrollTop <= clientHeight) {
            // adjust 50 to your requirement
            handleUsernameFetch();
        }
    };

    return (
        <div className="px-2 sm:md:lg:px-0 flex justify-center items-center mt-[1%] mb-[3%] sm:mb-0 pb-20 h-screen w-full">
            <div className={`${window.innerHeight < 1000 ? 'h-[95%]' : 'h-[70%]'} w-full sm:md:lg:w-2/3 h-full border rounded-lg p-4 border-[3px] shadow-xl shadow-gray-600`}>
                <div className="flex flex-col justify-center items-center mt-8 mb-[-5rem]">
                    <div className="relative w-3/4 sm:w-1/4">
                        <div className="relative mb-8">
                            <input
                                maxLength={220}
                                id={'Username'}
                                autoComplete="off"
                                key={'Username'}
                                name={'Username'}
                                className={`shadow shadow-gray-600 peer placeholder-transparent overflow-hidden h-12 w-full p-4 ${
                                    userSearch ? 'rounded-t-lg' : 'rounded-lg'
                                } border-2 border-gray-600 text-black`}
                                placeholder="Username"
                                onChange={event => setUserSearch(event.target.value)}
                            />
                            <label
                                htmlFor={'username'}
                                className="pointer-events-none absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[1.6rem] peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            >
                                Search by Username
                            </label>
                            <div className="absolute top-1/2 transform -translate-y-1/2 right-4">
                                <AiOutlineSearch size={20} />
                            </div>
                        </div>
                        {userSearch && (
                            <div
                                onScroll={handleUserNameScroll}
                                ref={scrollRef}
                                className="scrollbar-div overflow-y-auto h-[15rem] border-2 border-gray-600 bg-white w-full rounded-b-lg absolute z-30 mt-[-2rem]"
                            >
                                {listOfUsers.map((listOfUsers, index) => (
                                    <button
                                        key={index}
                                        className="text-gray-700 px-4 py-2 text-sm bg-black bg-opacity-0 w-full hover:bg-opacity-10 text-start border-b-2 border-gray-700"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id={`menu-item-${index}`}
                                        onClick={() => handleUserSearchBarClick(listOfUsers.display_name)}
                                    >
                                        <div className="flex items-center space-x-2">
                                            {listOfUsersDisplayPictures[index] ? (
                                                <img
                                                    src={listOfUsersDisplayPictures[index]}
                                                    className="w-11 rounded-full"
                                                    alt="displayPic"
                                                />
                                            ) : (
                                                <CgProfile className="w-11 h-11" />
                                            )}
                                            <p className="text-md font-bold">{listOfUsers.display_name}</p>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    {isExploreIMGsLoading ? (
                        <div className="flex justify-center items-center w-full h-[17rem] mx-2">
                            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-2"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <div
                            ref={scrollDiv}
                            className={`${layoutClass} gap-2 ${
                                exploreIMGs.length > 3 ? 'h-[36rem] sm:h-[42.2rem] overflow-y-scroll' : 'h-[18.15rem]'
                            } py-1 w-fit rounded-lg scrollbar-div z-20 px-10 pb-12`}
                        >
                            {exploreIMGs.map((imageINFO, index) => (
                                <div
                                    key={`${imageINFO?.image_id || index}`}
                                    className={`px-2 w-[21.5rem] h-fit ${(window.innerWidth < 1050 && window.innerWidth >= 640) ? 'w-[10.5rem]' :
                                        (window.innerWidth < 1500 && window.innerWidth >= 1050) ? 'w-[13rem]' :
                                            (window.innerWidth < 1700 && window.innerWidth >= 1500) ? 'w-[15rem]' : ''} flex relative`}
                                >
                                    <img
                                        alt="selectedImage"
                                        onClick={() => handleImageClick(imageINFO)}
                                        className={'border rounded-md cursor-pointer h-auto w-half object-cover bg-black bg-opacity-0 hover:opacity-60 shadow-xl shadow-gray-600'}
                                        src={imageINFO.image_url}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    {clickedImageModal && (
                        <div className="border bg-white fixed top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 z-50 w-[80%] h-[50%] sm:w-1/3 sm:h-1/2 flex flex-col justify-center items-center rounded-lg shadow-lg">
                            <div className="relative h-full flex flex-col items-center justify-center space-y-4 ">
                                <button
                                    type="button"
                                    className="absolute right-2 top-2 p-2 hover:bg-gray-300 rounded-lg"
                                    onClick={handleCloseModal}
                                >
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                                <div className="flex flex-col items-center">
                                    {clickedImageUserProfilePic ? (
                                        <img
                                            src={clickedImageUserProfilePic}
                                            alt="user profile"
                                            className="object-cover h-auto w-1/3 sm:md:lg:w-1/6 rounded-full border border-black"
                                        />
                                    ) : (
                                        <CgProfile size={90} />
                                    )}
                                    <button onClick={() => handleUsernameClick(clickedImageUsername)} className="">
                                @<span className="hover:underline">{clickedImageUsername}</span>
                                    </button>
                                </div>
                                <img src={currentImage} alt="selected image" className="w-1/2 h-auto rounded-lg" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewClothingLines;
