import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGatewayClient } from './utils';
import { UserContext } from '../contexts/UserContext';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdOutlineShoppingBag } from 'react-icons/md';
import ProductOverlays from './ProductOverlays';

const OrderCard = () => {
    const navigate = useNavigate();
    const [isOrderInfoFetched, setIsOrderInfoFetched] = useState(false);
    const [divHeight, setDivHeight] = useState(0);
    const divRef = useRef(null);

    type OrderInfoType = {
        created_at: string;
        display_name: string;
        email: string;
        fulfillment_status: string;
        items: any[]; // Replace 'any' with the specific type if you know it
        packages: {
            min_delivery_date: string;
            max_delivery_date: string;
            tracking_url: string;
        }[];
        order_id: string;
        shipping_address: {
            street: string;
            city: string;
            postalCode: string;
        };
        total: {
            subtotal: number;
            tax: number;
            shipping: number;
            discount: number;
            total: number;
        };
        user_id: string;
    };

    const [orderInfo, setOrderInfo] = useState<OrderInfoType | null>(null);
    const [order_id, setOrder_id] = useState<string | undefined>();
    const { user_ID } = useContext(UserContext);
    const { order_id: urlOrderID } = useParams<{ order_id: string }>();

    useEffect(() => {
        if (urlOrderID) {
            setOrder_id(urlOrderID);
        }
    }, [urlOrderID]);

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const parseShippingAddress = (address: string) => {
        const parts = address.split(',').map(part => part.trim());
        return {
            street: parts[0],
            city: parts.length > 2 ? parts.slice(1, -1).join(', ') : '',
            postalCode: parts[parts.length - 1]
        };
    };

    const fetchOrderInfo = async () => {
        const queryParams = {
            user_id: user_ID,
            ...(order_id ? { order_id: order_id } : {})
        };

        try {
            setIsOrderInfoFetched(false);
            const response = await apiGatewayClient({
                path: 'get_order_info',
                method: 'GET',
                queryParams: queryParams
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            if (data.shipping_address) {
                data.shipping_address = parseShippingAddress(data.shipping_address);
            }
            if (data.packages) {
                data.packages = data.packages.map(pkg => ({
                    ...pkg,
                    min_delivery_date: formatDate(pkg.min_delivery_date),
                    max_delivery_date: formatDate(pkg.max_delivery_date)
                }));
            }
            setOrderInfo(data);
            setIsOrderInfoFetched(true);
        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    };

    useEffect(() => {
        if (user_ID) {
            fetchOrderInfo();
        }
        console.log(user_ID);
    }, [user_ID]);

    useEffect(() => {
        document.documentElement.classList.add('scrollbar-div');
        return () => {
            document.documentElement.classList.remove('scrollbar-div');
        };
    }, []);

    useEffect(() => {
        if (user_ID) {
            fetchOrderInfo();
        }
    }, [user_ID]);

    useEffect(() => {
        if (divRef.current) {
            setDivHeight(divRef.current.offsetHeight);
            console.log(divRef.current.offsetHeight);
        }
    }, [orderInfo]);

    const renderPackageSection = (pkg, index) => {
        return (
            <div key={index} className="mb-4 border border-gray-300 p-3">
                <h3 className="font-bold text-lg mb-2">Package {index + 1}</h3>
                <div className="mb-4 flex -space-x-4 sm:space-x-8 border-b border-gray-300 pb-2">
                    <span className="font-bold">Delivery (ETA)</span>
                    <span className="text-gray-600 break-words overflow-hidden">
                        {pkg 
                            ? `${pkg.min_delivery_date} - ${pkg.max_delivery_date}`
                            :  'Sorry, this information is currently unavailable.'}
                    </span>
                </div>
                <div className={`flex ${pkg.tracking_url ? 'space-x-8' : 'space-x-6'}`}>
                    <span className="font-bold">Tracking</span>
                    {pkg.tracking_url ? (
                        <a href={pkg.tracking_url} className="text-indigo-600 hover:text-indigo-800 font-bold underline ml-2">
                            Tracking URL
                        </a>
                    ) : (
                        <span className="text-gray-600 break-words overflow-hidden ml-2">
                            Sorry, this information is currently unavailable.
                        </span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="h-full sm:h-screen flex items-center justify-center">
            <div className={`bg-white p-8 rounded-lg shadow-md w-full ${isOrderInfoFetched ? 'lg:w-auto' : 'lg:w-2/3'} border`}>
                {isOrderInfoFetched && orderInfo ? (
                    <div className='flex flex-col lg:flex-row w-full sm:gap-4'>
                        <div className=''>
                            <h1 className="text-2xl font-bold mb-4">Order Details</h1>
                            <div className='flex items-center mb-4'>
                                <AiFillCheckCircle size={25}/>
                                <div className='ml-2'>
                                    <h1 className="text-sm sm:text-lg">Order #{orderInfo.order_id}</h1>
                                    <h1 className="text-xl font-bold ">Thank you, {orderInfo.display_name}!</h1>
                                </div>
                            </div>
                            <div className="border border-gray-300 p-3 mb-4 text-lg">
                                <h1 className="font-bold mb-4">Order Updates</h1>
                                <div className="mb-4 flex space-x-12  border-b border-gray-300 pb-2">
                                    <h3 className="font-bold">Status</h3>
                                    <p className="text-gray-600 break-words overflow-hidden">
                                        {orderInfo.fulfillment_status}
                                    </p>
                                </div>
                                {orderInfo.packages.map(renderPackageSection)}
                            </div>
                            <div className="border border-gray-300 p-3 text-lg">
                                <div className="mb-4 flex space-x-8  border-b border-gray-300 pb-2">
                                    <h3 className="font-bold">Contact</h3>
                                    <p className="text-gray-600 break-words overflow-hidden">{orderInfo.email}</p>
                                </div>
                                <div className="mb-4 flex space-x-8 border-b border-gray-300 pb-2">
                                    <h3 className="font-bold">Address</h3>
                                    <div>
                                        <p className="text-gray-600">{orderInfo.shipping_address.street}</p>
                                        <p className="text-gray-600">{orderInfo.shipping_address.city}</p>
                                        <p className="text-gray-600">{orderInfo.shipping_address.postalCode}</p>
                                    </div>
                                </div>
                                <div className=" flex space-x-6">
                                    <h3 className="font-bold">Payment</h3>
                                    <p className="text-gray-600">Debit/Credit</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-px bg-gray-300"></div>
                        <div className="mt-4 sm:mt-0">
                            <div className="pb-4">
                                <div className="text-base sm:text-xl font-bold mb-2 flex items-center">
                                    <MdOutlineShoppingBag size={25} />
                                    <p className="ml-1">Your order</p>
                                    <span className="inline-flex items-center justify-center px-2.5 py-1.5 text-xs font-bold leading-none text-white ml-2 bg-black rounded-full">
                                        {orderInfo.items.length}
                                    </span>
                                </div>
                                <div
                                    className="overflow-y-auto scrollbar-div border-b border-gray-300 mb-4 "
                                    style={{
                                        maxHeight: orderInfo.items.length > 1 ? `${divHeight}px` : 'none'
                                    }}
                                >
                                    {orderInfo &&
                                        orderInfo.items.map((item, index) => (
                                            <div
                                                ref={index === 0 ? divRef : null}
                                                key={index}
                                                className="flex min-h-max border-b items-center"
                                            >
                                                <ProductOverlays
                                                    color={item.color}
                                                    product_id={item.product_id}
                                                    image_url={item.image_url}
                                                />
                                                <div className="flex flex-col justify-start items-center ">
                                                    <ul className="text-sm sm:text-lg">
                                                        <li className="mb-1">
                                                            <span className="font-semibold">T Shirt</span>
                                                        </li>
                                                        <li className="">
                                                            <span className="font-semibold">Color: </span>
                                                            {item.color}
                                                        </li>
                                                        <li className="flex space-x-2">
                                                            <span className="font-semibold ">Prompt:</span>
                                                            <span className="inline-block max-w-xs overflow-hidden text-ellipsis">
                                                                {item.prompt}
                                                            </span>
                                                        </li>
                                                        <li className="mb-1">
                                                            <span className="font-semibold">Subtotal: </span>$
                                                            {item.price}
                                                        </li>
                                                        <li className="mb-2">
                                                            <span className="font-semibold border border-gray-300 px-1.5">
                                                                x{item.quantity}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <div>
                                    <div className='border-b border-gray-300 pb-2 text-lg'>
                                        <div className="grid grid-cols-2 mt-4 gap-32">
                                            <span className="font-bold mr-2">Subtotal</span>
                                            <span className='text-right monetary-value'>
                                            ${orderInfo.total.subtotal.toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="grid grid-cols-2 mt-4 gap-32">
                                            <span className="font-bold mr-2">Shipping</span>
                                            <span className='text-right monetary-value'>
                                            ${orderInfo.total.shipping.toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="border-b border-gray-300 pb-2 text-lg">
                                        <div className="grid grid-cols-2 mt-4 gap-32">
                                            <span className="font-bold mr-2">Tax</span>
                                            <span className='text-right monetary-value'>
                                            ${orderInfo.total.tax.toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="grid grid-cols-2 text-base sm:text-lg mt-4 gap-32">
                                            <span className="font-bold mr-2">Total</span>
                                            <span className='text-right monetary-value'>
                                            ${orderInfo.total.total.toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <button
                                            onClick={() => navigate('/')}
                                            className="text-white border border-gray-700 font-bold rounded-lg text-base px-3 py-2 mt-2 shadow-md shadow-gray-900 bg-neutral-900 hover:bg-neutral-700"
                                        >
                                            <div className="flex justify-center items-center space-x-2">
                                                <span className="">Continue Shopping</span>
                                                <AiFillCheckCircle size={25} />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center items-center w-full h-[17rem] mx-2">
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-2"></div>
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderCard;
