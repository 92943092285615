import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { BiSolidEditAlt } from 'react-icons/bi';
import { FaRedo, FaUndo } from 'react-icons/fa';
import { GrClear } from 'react-icons/gr';
import { IoClose } from 'react-icons/io5';
import { PiEraserBold } from 'react-icons/pi';
import { Stage, Layer, Line } from 'react-konva';
import { isUsingMobile } from './utils';

export const ImageEdit = forwardRef<any, any>(({ selectedImage, setEditMask, isLoading, closeEdit }, ref) => {
    ImageEdit.displayName = 'ImageEdit';

    const [tool, setTool] = React.useState('pen');
    const [lines, setLines] = React.useState([]);
    const isDrawing = React.useRef(false);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const imageRef = useRef(null);
    const stageRef = React.useRef(null);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [size, setSize] = useState(50);
    const [showSizePanel, setShowSizePanel] = useState(false);

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const clearCanvas = () => {
        setLines([]);
        setEditMask(handleExport());
    };
    useImperativeHandle(ref, () => ({
        clearCanvas
    }));

    const undo = () => {
        const lastState = undoStack.pop();
        if (lastState) {
            setRedoStack([...redoStack, lines]);
            setLines(lastState);
        } else {
            setLines([]);
        }
        setEditMask(handleExport());
    };

    const redo = () => {
        const nextState = redoStack.pop();
        if (nextState) {
            setUndoStack([...undoStack, lines]);
            setLines(nextState);
        } else {
            setLines([]);
        }
        setEditMask(handleExport());
    };


    const updateStageSize = () => {
        if (imageRef.current) {
            setStageSize({
                width: imageRef.current.offsetWidth,
                height: imageRef.current.offsetHeight
            });
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateStageSize);
        return () => {
            window.removeEventListener('resize', updateStageSize);
        };
    }, []);

    const handleMouseDown = (e) => {
        setShowSizePanel(false);
        isDrawing.current = true;
        e.evt.preventDefault();
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y], size }]);
        setRedoStack([]);
    };

    const handleMouseMove = (e) => {
        if (!isDrawing.current) {
            return;
        }
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        const lastLine = lines[lines.length - 1];
        lastLine.points = lastLine.points.concat([point.x, point.y]);
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
        setUndoStack([...undoStack, lines]);
        setEditMask(handleExport());
    };

    const handleExport = (): string => {
        const uri = stageRef.current.toDataURL();
        return uri.replace('data:image/png;base64,', '');
    };

    const buttonSize = (): string => {
        return isUsingMobile() ?  'h-[2.5rem] w-[2.5rem]' : 'h-[3.5rem] w-[3.5rem]';
    };

    return (
        <div className={'flex flex-col'}>
            <div className={'flex gap-2 right-0 m-2 justify-end'}>
                <button
                    className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 ${buttonSize()} whitespace-nowrap`}
                    onClick={() => {
                        setShowSizePanel(tool !== 'pen' ? true : !showSizePanel);
                        setTool('pen');
                    }
                    }
                >
                    <div className={'flex justify-center items-center'}>
                        <BiSolidEditAlt size={30} className={'text-white'}></BiSolidEditAlt>
                    </div>
                    {showSizePanel && (<div
                        className={'absolute flex flex-col items-center shadow-md shadow-gray-700 text-white border-2' +
                            `border-[black] bg-neutral-900 h-[3.5rem] rounded-md z-50 ${isUsingMobile() ? 'ml-[-2rem] mt-2' : 'ml-[-1rem] mt-4'}`}>
                        <input
                            type="range"
                            min="1"
                            max="100"
                            value={size}
                            onChange={handleSizeChange}
                            className="mt-2 ml-2 mr-2"
                        />
                        <label className={'text-white font-bold text-lg'}>
                            Size: {size}
                        </label>
                    </div>)}
                </button>
                <button
                    className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 ${buttonSize()} whitespace-nowrap`}
                    onClick={() => {
                        setShowSizePanel(tool !== 'eraser' ? true : !showSizePanel);
                        setTool('eraser');
                    }
                    }
                >
                    <div className={'flex justify-center items-center'}>
                        <PiEraserBold size={30} className={'text-white'}></PiEraserBold>
                    </div>
                </button>
                <button
                    className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 ${buttonSize()} whitespace-nowrap`}
                    onClick={clearCanvas}
                >
                    <div className={'flex justify-center items-center'}>
                        <GrClear size={30} className={'text-white'}></GrClear>
                    </div>
                </button>
                <button
                    className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 ${buttonSize()} whitespace-nowrap`}
                    onClick={undo}
                >
                    <div className={'flex justify-center items-center'}>
                        <FaUndo size={25} className={'text-white'}></FaUndo>
                    </div>
                </button>
                <button
                    className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 ${buttonSize()} whitespace-nowrap`}
                    onClick={redo}
                >
                    <div className={'flex justify-center items-center'}>
                        <FaRedo size={25} className={'text-white'}></FaRedo>
                    </div>
                </button>
                <button
                    className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 ${buttonSize()} whitespace-nowrap`}
                    onClick={closeEdit}
                >
                    <div className={'flex justify-center items-center'}>
                        <IoClose size={40} className={'text-white'}></IoClose>
                    </div>
                </button>
            </div>
            <div className={'relative'}>
                <div>
                    <div>
                        <img
                            ref={imageRef}
                            onLoad={updateStageSize}
                            onClick={() => {}}
                            alt="Generated Image"
                            className={`absolute inset-0 bg-white h-auto w-full mb-4 border-4 border-gray-700 shadow-lg shadow-black ${isLoading ? 'opacity-50' : ''}`}
                            src={selectedImage.imageUrl}
                        />
                    </div>
                    <div className={'absolute inset-0'}>
                        <Stage
                            opacity={1}
                            width={stageSize.width}
                            height={stageSize.height}
                            onMouseDown={handleMouseDown}
                            onMousemove={handleMouseMove}
                            onMouseup={handleMouseUp}
                            onTouchStart={handleMouseDown}
                            onTouchMove={handleMouseMove}
                            onTouchEnd={handleMouseUp}
                            ref={stageRef}
                        >
                            <Layer>
                                {lines.map((line, i) => (
                                    <Line
                                        key={i}
                                        points={line.points}
                                        stroke="#FF0000"
                                        opacity={line.tool === 'eraser' ? 1 : 0.5}
                                        strokeWidth={line.size || 50}
                                        tension={0.5}
                                        lineCap="round"
                                        lineJoin="round"
                                        globalCompositeOperation={
                                            line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                        }
                                    />
                                ))}
                            </Layer>
                        </Stage>
                        {isLoading && (
                            <div className={'flex gap-2 absolute ml-[45%] mt-[-55%]'}>
                                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-2"></div>
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

