import React, { useContext, useState, useEffect } from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { CartContext } from '../contexts/CartContext';
import Logo from '../images/UnbrandedAI_logo.jpg';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ setIsShowCart, handleClick, setIsSideBarVisible, isSideBarVisible }) => {
    const { items } = useContext(CartContext);
    const totalQuantity = items.reduce((total, item) => total + item.quantity, 0);
    const navigate = useNavigate();

    const shoppingCartOnClick = () => {
        setIsShowCart(true);
        handleClick('NavBar', 'Shopping Cart', '');
    };

    const initialStrings = [
        'Your Style, AI Amplified',
        'Your Personal Brand Architect',
        'Where Creativity Meets Technology'
    ];
    const [randomStrings, setRandomStrings] = useState(initialStrings);

    const getRandomStrings = () => {
        const tempStrings = [...initialStrings]; // create a copy of the original array
        tempStrings.sort(() => Math.random() - 0.5); // shuffle the array
        setRandomStrings(tempStrings);
    };

    // use this effect to update randomStrings when the component mounts
    useEffect(() => {
        getRandomStrings();
        // eslint-disable-next-line
    }, []);

    // use this effect to update randomStrings each time it changes (loop finishes)
    useEffect(() => {
        const timer = setTimeout(() => {
            getRandomStrings();
        }, randomStrings.length * 7000); // adjust timing based on your typewriting speed and pause duration
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [randomStrings]);

    return (
        <div
            className={`fixed sm:relative flex justify-between sm:justify-end py-4 px-4 bg-white sm:bg-transparent w-full text-black z-50 h-[5rem] ${
                isSideBarVisible ? ' absolute w-full' : 'sm:absolute sm:right-0'
            }`}
        >
            <img
                src={Logo}
                alt="logo"
                onClick={() => navigate('/')}
                className="w-12 lg:hidden absolute top-4 left-4"
            />
            <div className="absolute top-0 right-2">
                <button
                    className="flex bg-white items-center rounded-lg hover:bg-[#f3f4f6] py-4 px-4 text-black top-1 right-1 mt-2"
                    onClick={shoppingCartOnClick}
                >
                    <span className="absolute right-2 top-4 text-[0.8125rem] bg-[#EC1616] h-[1.125rem] w-[1.125rem] rounded-full grid place-items-center text-white">
                        {totalQuantity}
                    </span>
                    <AiOutlineShoppingCart className="text-black" size={35} />
                </button>
            </div>
        </div>
    );
};

export default Navbar;
