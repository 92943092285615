import React, { useEffect, useState, useContext } from 'react';
import { auth, provider } from './config';
import { signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, get, child } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiFillEye } from 'react-icons/ai';
import { UserContext } from '../../contexts/UserContext';
import { apiGatewayClient } from 'components/utils';

const SignIn = ({ userIP, setIsUserOnLogin }) => {
    const navigate = useNavigate();
    const { userEmail, setRealDisplayName, setUserEmail, setFirebaseUID, user_ID } = useContext(UserContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [RegisterSignUP, setRegisterSignUP] = useState(false);

    const db = getDatabase();

    const handleSignUP = async event => {
        createUserWithEmailAndPassword(auth, userEmail, password)
            .then(async data => {
                if (user_ID === userIP) {
                    await apiGatewayClient({
                        path: 'create_user',
                        method: 'POST',
                        body: {
                            user_id: data.user.uid,
                            email: userEmail
                        }
                    });
                }
                setRegisterSignUP(!RegisterSignUP);

                // Write user data to Realtime Database
                set(ref(db, 'users/' + username), {
                    email: data.user.email,
                    UID: data.user.uid
                }).then(async () => {
                    await apiGatewayClient({
                        path: 'set_user_display_name',
                        method: 'POST',
                        body: {
                            user_id: data.user.uid,
                            display_name: username
                        }
                    });
                }).catch(error => {
                    console.error('Error saving data:', error);
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleSignIN = event => {
        event.preventDefault();
        signInWithEmailAndPassword(auth, userEmail, password)
            .then(async data => {
                if (user_ID === userIP) {
                    await apiGatewayClient({
                        path: 'create_user',
                        method: 'POST',
                        body: {
                            user_id: data.user.uid,
                            email: userEmail
                        }
                    });
                    navigate('/');
                    setFirebaseUID(data.user.uid);
                    setRealDisplayName(username);
                    setIsUserOnLogin(true);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const generateUniqueUsername = async baseUsername => {
        const usersRef = ref(db, 'users');
        let notFoundUsername = true;
        while (notFoundUsername) {
            const randomSuffix = Math.floor(Math.random() * 10); // Generate a random number 0-9
            const usernameWithSuffix = `${baseUsername}${randomSuffix}`;

            const usernameExistsSnapshot = await get(child(usersRef, usernameWithSuffix)).catch(error => {
                console.error('Error checking username existence:', error);
            });

            if (!(usernameExistsSnapshot as any).exists()) {
                notFoundUsername = false;
                return usernameWithSuffix; // Found a unique username
            }

            // Username already exists, try generating a new random number
        }
    };

    const handleGoogleSignIN = () => {
        signInWithPopup(auth, provider).then(async data => {
            setFirebaseUID(data.user.uid);
            const userEmail = data.user.email;
            await apiGatewayClient({
                path: 'create_user',
                method: 'POST',
                body: {
                    user_id: data.user.uid,
                    email: userEmail
                }
            });

            // Reference to all users
            const usersRef = ref(db, 'users');
            get(usersRef).then(async snapshot => {
                const users = snapshot.val();
                let userEmailExists = false;
                let existingUsername = null;

                // Check if user with the email already exists
                for (const key in users) {
                    // ANTHONY: This is potentially going to be really slow as our users grow, does the API offer this functionality?
                    if (users[key].email === userEmail) {
                        userEmailExists = true;
                        existingUsername = key;
                        break;
                    }
                }

                if (userEmailExists) {
                    // if user email is in DB, Sign them in
                    setRealDisplayName(existingUsername);
                    navigate('/');
                    setIsUserOnLogin(true);
                } else {
                    // if user email is not in DB, grab base of email and check if in DB
                    const baseUsername = data.user.email.replace('@gmail.com', '');

                    let userNameExists = false;
                    for (const key in users) {
                        if (users[key] === baseUsername) {
                            userNameExists = true;
                            existingUsername = key;
                            break;
                        }
                    }
                    if (userNameExists) {
                        // if base of email/username exists, use generateUniqueUsername function to add a random digit to the end of the basename
                        const uniqueUsername = await generateUniqueUsername(baseUsername);
                        if (uniqueUsername) {
                            set(ref(db, 'users/' + uniqueUsername), {
                                email: data.user.email,
                                UID: data.user.uid
                            });
                            await apiGatewayClient({
                                path: 'set_user_display_name',
                                method: 'POST',
                                body: {
                                    user_id: data.user.uid,
                                    display_name: uniqueUsername
                                }
                            });
                            setRealDisplayName(uniqueUsername);
                            navigate('/');
                            setIsUserOnLogin(true);
                        } else {
                            console.error('Could not generate a unique username for new user');
                            // Handle the case where you can't generate a unique username
                        }
                    } else {
                        //if the base username was not in the DB, just use the base name as their username
                        await set(ref(db, 'users/' + baseUsername), {
                            email: data.user.email,
                            UID: data.user.uid
                        });
                        await apiGatewayClient({
                            path: 'set_user_display_name',
                            method: 'POST',
                            body: {
                                user_id: data.user.uid,
                                display_name: baseUsername
                            }
                        });
                        setRealDisplayName(baseUsername);
                        navigate('/');
                        setIsUserOnLogin(true);
                    }
                }
            });
        });
    };

    const handleRegisterSignUP = () => {
        setRegisterSignUP(!RegisterSignUP);
    };

    const handlePasswordVisibility = event => {
        event.preventDefault();
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        setIsUserOnLogin(false);
    }, [user_ID]);

    useEffect(() => {
        const email = localStorage.getItem('email');
        if (email) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center justify-center">
                {RegisterSignUP ? (
                    <div className="w-full">
                        <div
                            className="flex items-center text-gray-500 hover:text-gray-600 hover:scale-105 duration-300 cursor-pointer"
                            onClick={handleRegisterSignUP}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-chevron-left"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M15 6l-6 6l6 6"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-chevron-left"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M15 6l-6 6l6 6"></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-chevron-left"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M15 6l-6 6l6 6"></path>
                            </svg>
                        </div>
                        <div className="px-8 py-2">
                            <h2 className="font-bold text-2xl text-[#002D74]">Register</h2>
                            <form action="" className="flex flex-col gap-4">
                                <input
                                    className="p-2 mt-8 rounded-xl border"
                                    type="username"
                                    name="username"
                                    placeholder="Username"
                                    onChange={event => setUsername(event.target.value)}
                                />
                                <input
                                    className="p-2  rounded-xl border"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    onChange={event => setUserEmail(event.target.value)}
                                />
                                <div className="relative">
                                    <input
                                        className="p-2 rounded-xl border w-full"
                                        type={passwordVisible ? 'text' : 'password'}
                                        name="password"
                                        placeholder="Password"
                                        onChange={event => setPassword(event.target.value)}
                                    />
                                    <button
                                        onClick={handlePasswordVisibility}
                                        className="absolute top-1/2 transform -translate-y-1/2 right-3 text-gray-500"
                                    >
                                        {passwordVisible ? <AiFillEye size={20} /> : <AiOutlineEye size={20} />}
                                    </button>
                                </div>
                                <button
                                    onClick={event => handleSignUP(event)}
                                    type="button"
                                    className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300"
                                >
                                    Sign Up
                                </button>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="w-full px-8 py-2">
                        <h2 className="font-bold text-2xl text-[#002D74]">Login</h2>
                        <p className="text-xs mt-4 text-[#002D74]">If you are already a member, easily log in</p>

                        <form action="" className="flex flex-col gap-4">
                            <input
                                className="p-2 mt-8 rounded-xl border"
                                type="email"
                                name="email"
                                placeholder="Email"
                                onChange={event => setUserEmail(event.target.value)}
                            />
                            <div className="relative">
                                <input
                                    className="p-2 rounded-xl border w-full"
                                    type={passwordVisible ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Password"
                                    onChange={event => setPassword(event.target.value)}
                                />
                                <button
                                    onClick={handlePasswordVisibility}
                                    className="absolute top-1/2 transform -translate-y-1/2 right-3 text-gray-500"
                                >
                                    {passwordVisible ? <AiFillEye size={20} /> : <AiOutlineEye size={20} />}
                                </button>
                            </div>
                            <button
                                onClick={handleSignIN}
                                className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300"
                            >
                                Login
                            </button>
                        </form>

                        <div className="mt-6 grid grid-cols-3 items-center text-gray-400">
                            <hr className="border-gray-400" />
                            <p className="text-center text-sm">OR</p>
                            <hr className="border-gray-400" />
                        </div>

                        <button
                            onClick={handleGoogleSignIN}
                            className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]"
                        >
                            <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px">
                                <path
                                    fill="#FFC107"
                                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                                />
                                <path
                                    fill="#FF3D00"
                                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                                />
                                <path
                                    fill="#4CAF50"
                                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                                />
                                <path
                                    fill="#1976D2"
                                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                                />
                            </svg>
                            Login with Google
                        </button>
                        <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
                            <p>Don&apos;t have an account?</p>
                            <button
                                onClick={handleRegisterSignUP}
                                className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300"
                            >
                                Register
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignIn;
