import React, { useEffect, useState, useContext } from 'react';
import { FaCompass } from 'react-icons/fa';
import { UserContext } from '../contexts/UserContext';
import { AiFillHome } from 'react-icons/ai';
import { BsArrowUpCircle, BsPersonCircle } from 'react-icons/bs';
import { FaHistory } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { auth } from './GoogleSignIn/config';
import { apiGatewayClient } from './utils';
import { GoSignIn, GoSignOut } from 'react-icons/go';

const MobileNavBar = (props: { isSettingsPage: boolean }) => {
    const { user_ID, realDisplayName, setFirebaseUID, setRealDisplayName } = useContext(UserContext);
    const navigate = useNavigate();
    const [displayIMG, setDisplayIMG] = useState();
    const [slideIn, setSlideIn] = useState(true);
    const [isSignedOut, setIsSignedOut] = useState(true);

    const onArrowClick = () => {
        setSlideIn(!slideIn);
    };

    const getDisplayIMG = async () => {
        if (user_ID) {
            try {
                const response = await apiGatewayClient({
                    path: 'get_user_display_picture',
                    method: 'GET',
                    queryParams: {
                        user_id: user_ID
                    }
                });
                const data = await response.json();
                if (data) {
                    const imageINFO = {
                        image_url: data.image_url,
                        id: data.image_id
                    };
                    setDisplayIMG(imageINFO.image_url);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    useEffect(() => {
        getDisplayIMG();
        if (realDisplayName) {
            setIsSignedOut(false);
        }
    }, [getDisplayIMG, user_ID]);

    const handleSignIn = () => {
        navigate('/signIn'); // Redirect to login page after sign out
    };

    const handleSignOut = () => {
        auth.signOut()
            .then(() => {
                // Sign-out successful.
                localStorage.clear();
                sessionStorage.clear();
                setIsSignedOut(true);
                setFirebaseUID(null);
                setRealDisplayName(null);
                navigate('/');
            })
            .catch(error => {
                console.error('Error', error);
            });
    };

    return (
        <div>
            <div
                className={`fixed w-full z-50 -bottom-4 flex justify-center transition-all ease-in-out duration-300 ${
                    slideIn ? 'translate-y-full' : 'translate-y-0'
                } `}
            >
                <button onClick={() => onArrowClick()} className="">
                    <BsArrowUpCircle size={50} />
                </button>
            </div>
            <div
                className={`fixed z-50 flex justify-center bottom-0 bg-white w-full p-3 border-t border-black sm:md:lg:hidden transition-all ease-in-out duration-500  ${
                    slideIn ? 'translate-y-0' : 'translate-y-full'
                }`}
            >
                <div className="flex justify-center items-center space-x-20">
                    <button onClick={() => navigate('/')} className="transition hover:scale-125">
                        <AiFillHome size={32} />
                    </button>
                    <button onClick={() => navigate('/explore')} className="transition hover:scale-125">
                        <FaCompass size={32} />
                    </button>
                    <button onClick={() => navigate('/orders')} className="transition hover:scale-125">
                        <FaHistory size={32} />
                    </button>
                    {!isSignedOut ? (
                        <div className={'pr-8'}>
                            {props.isSettingsPage && (<button
                                onClick={handleSignOut}
                                className={'group flex items-center absolute bottom-12 py-10 right-12 text-red-500 scale-125'}
                            >
                                <GoSignOut/>
                                <span className="pl-2 text-red-500">Sign Out</span>
                            </button>)}
                            <button
                                onClick={() => navigate(`/profile/${realDisplayName}`)}
                                className="transition hover:scale-125 absolute bottom-3"
                            >
                                {displayIMG ? (
                                    <img src={displayIMG} alt="User Display IMG" className="w-8 h-8 rounded-full" />
                                ) : (
                                    <BsPersonCircle size={32} className={'mb-[-0.3rem]'}/>
                                )}
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={handleSignIn}
                            className="text-black hover:bg-[#f3f4f6]"
                        >
                            <GoSignIn size={32} className="w-6 h-6 group-hover:text-[#0284c7]" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MobileNavBar;
