import moment from 'moment';
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import { Link } from 'react-scroll';
import { UserContext } from '../contexts/UserContext';
import { CgProfile } from 'react-icons/cg';
import { AiOutlineStar, AiFillStar, AiOutlineRobot, AiOutlineSetting } from 'react-icons/ai';
import { IoImagesOutline } from 'react-icons/io5';
import { RiProfileLine } from 'react-icons/ri';
import { getDatabase, ref, onValue, update, get } from 'firebase/database';
import ImageContext, { Image } from '../contexts/ImageContext';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGatewayClient, createGetImagePayload } from './utils';
import ErrorPrompts from './ErrorPrompts';
import { pathConfig } from 'models/sideBarModels';
import { debounce } from 'lodash';

const ProfilePage = ({ setIsShowProduct, isShowProduct, setIsSettingsPage }) => {
    const [ModelDrop, setModelDrop] = useState(false);
    const [_isModelsLoading, setIsModelsLoading] = useState(false);
    const [isImageHistoryLoading, setIsImageHistoryLoading] = useState(false);
    const [isFavoritedIMGsLoading, setIsFavoritedIMGsLoading] = useState(false);
    const scrollDiv = useRef(null); // for image history div
    const scrollFavDiv = useRef(null);
    const [ showRelatedImages, setShowRelatedImages ] = useState(false);
    const [ relatedImages, setRelatedImages ] = useState([]);
    const [ groupedHistory, setGroupedHistory ] = useState({});
    const [ expandedIndex, setExpandedIndex ] = useState(null);
    const [ recentRootImageId, setRecentRootImageId ] = useState(null);

    const [models, setModels] = useState([]);
    const [_displayName, setDisplayName] = useState(''); // From Google Authenticator
    const [userDisplayName, setUserDisplayName] = useState(''); // From user setting
    const [displayIMG, setDisplayIMG] = useState('');
    const [activeTab, setActiveTab] = useState('Favourites');
    const verticalScrollDiv = useRef(null);

    const handleActiveTab = tabName => {
        setActiveTab(tabName);
    };

    const [imageHistory, setImageHistory] = useState([]);
    const [favoritedIMGs, setFavoritedIMGs] = useState([]);
    const [_isFavourite, setIsFavourite] = useState(false);

    useEffect(() => {
        setIsShowProduct(false);
        if (activeTab !== 'Settings') {
            setIsSettingsPage(false);
        }
    }, [activeTab]);

    const { realDisplayName, firebaseUID, setRealDisplayName } = useContext(UserContext);
    const { user_ID: urlDisplayName } = useParams();

    const db = getDatabase();
    const [searchedUID, setSearchedUID] = useState();
    const [user_ID, setUser_ID] = useState();

    useEffect(() => {
        if (firebaseUID === searchedUID) {
            setActiveTab('Image History');
        }
    }, [firebaseUID, searchedUID]);

    useEffect(() => {
        if (urlDisplayName) {
            const userRef = ref(db, 'users/' + urlDisplayName);
            onValue(userRef, snapshot => {
                const data = snapshot.val();
                if (data) {
                    setSearchedUID(data.UID);
                    if (data.UID !== firebaseUID) {
                        setUser_ID(data.UID);
                    } else {
                        setUser_ID(firebaseUID);
                    }
                }
            });
        }
    }, [urlDisplayName]);

    // eslint-disable-next-line
    const [displayImageId, setDisplayImageId] = useState('');
    const [changeDisplayModal, setChangeDisplayModal] = useState(false);
    const [currentDisplayImageInfo, setCurrentDisplayImageInfo] = useState(null);
    const [isFetchComplete, setIsFetchComplete] = useState(false);

    const navigate = useNavigate();
    const updateUsername = async newUsername => {
        const oldUserKey = urlDisplayName;
        const oldUserRef = ref(db, 'users/' + oldUserKey);
        const newUserRef = ref(db, 'users/' + newUsername);

        // Check if the new username already exists
        const newSnapshot = await get(newUserRef);
        if (newSnapshot.exists()) {
            console.error('Username already exists!');
            return;
        }

        // Get the old user data
        const oldSnapshot = await get(oldUserRef);
        if (oldSnapshot.exists()) {
            const userData = oldSnapshot.val();

            // Using a transaction to ensure atomicity
            const updates = {};
            updates['/users/' + oldUserKey] = null; // This deletes the old user key
            updates['/users/' + newUsername] = userData; // This sets the new user key with old data

            update(ref(db), updates)
                .then(() => {
                    setRealDisplayName(newUsername);
                    navigate(`/profile/${newUsername}`);
                })
                .catch(error => {
                    console.error('Failed to update username: ', error);
                });
        } else {
            console.error('No data available');
        }
    };

    const TrainedModelsComponent = () => {
        return (
            <div className="flex items-center space-x-4">
                <div className="flex font-bold text-center px-2 text-sm items-center md:lg:mr-6 md:lg:px-0 md:lg:text-base text-gray-400">
                    Credits Available: {availableCredits}
                </div>
                <div className="relative inline-block text-left my-2 z-30">
                    <div>
                        <button
                            onClick={handleModelDrop}
                            type="button"
                            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            id="menu-button"
                            aria-expanded="true"
                            aria-haspopup="true"
                        >
                            {selectedModelName ? selectedModelName : 'Trained Models'}
                            <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                    <div
                        className={`absolute left-0 z-10 mt-2 w-[11rem] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ${
                            ModelDrop ? 'transition ease-in duration-150' : 'transition ease-out duration-150 hidden'
                        }`}
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        tabIndex={-1}
                    >
                        <div className="py-1" role="none">
                            {
                                // Map over 'models' to create <a> tags for each model
                                models.map((model, index) => (
                                    <a
                                        key={index}
                                        className="text-gray-700 block px-4 py-2 text-sm"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id={`menu-item-${index}`}
                                        onClick={() => {
                                            handleModelClick(model);
                                            setSelectedModelName(model.model_name);
                                            setModelDrop(false);
                                        }}
                                    >
                                        {model.model_name}
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleSetDisplayName = async () => {
        const response = await apiGatewayClient({
            path: 'set_user_display_name',
            method: 'POST',
            body: {
                user_id: user_ID,
                display_name: userDisplayName
            }
        });
        const data = await response.json();
        if (data) {
            await updateUsername(userDisplayName);
        }
    };

    const handleSettingDisplayIMG = async imageInfo => {
        setChangeDisplayModal(true);
        setCurrentDisplayImageInfo(imageInfo);
    };
    const handleModalNo = async () => {
        setChangeDisplayModal(false);
    };
    const handleModalYes = async (currentDisplayImageInfo: Image) => {
        const response = await apiGatewayClient({
            path: 'set_user_display_picture',
            method: 'POST',
            body: {
                user_id: user_ID,
                image_id: currentDisplayImageInfo.imageId
            }
        });
        const data = await response.json();
        if (data) {
            setIsFetchComplete(!isFetchComplete);
            setDisplayIMG(currentDisplayImageInfo.imageUrl);
            setChangeDisplayModal(false);
        }
    };

    const showRelatedImagesOnClick = (rootImageId: string, index: number) => {
        if (expandedIndex === index) {
            setShowRelatedImages(!showRelatedImages);
        } else {
            setShowRelatedImages(false);
            setShowRelatedImages(true);
        }
        setRelatedImages(groupedHistory[rootImageId].slice(rootImageId === recentRootImageId ? 0 : 1));
    };

    const getNumberOfRelatedImages = (rootImageId: string, imageId: string) => {
        const subtractOne = rootImageId === recentRootImageId ? 0 : 1;
        return groupedHistory[rootImageId] ?
            groupedHistory[rootImageId].length - subtractOne : groupedHistory[imageId]?.length - subtractOne;
    };

    const handleModelDrop = () => {
        setModelDrop(!ModelDrop);
    };

    const handleFavouriteClick = async (imageInfo: Image) => {
        const newFavouriteStatus = !imageInfo.favorited;
        setIsFavourite(newFavouriteStatus);
        const response = await apiGatewayClient({
            path: 'set_favorited_image',
            method: 'POST',
            body: {
                user_id: user_ID,
                image_id: imageInfo.imageId,
                favorited: newFavouriteStatus
            }
        });
        const data = await response.json();
        if (data) {
            setImageHistory((prevState: Image[]) =>
                prevState.map((image: Image) =>
                    image.imageId === imageInfo.imageId ? { ...image, favorited: newFavouriteStatus } : image
                )
            );

            if (!newFavouriteStatus) {
                // If image is unfavourited
                setFavoritedIMGs((prevState: Image[]) =>
                    prevState.filter((image: Image) => image.imageId !== imageInfo.imageId));
            } else {
                // If image is favorited
                setFavoritedIMGs((prevState: Image[]) =>
                    [...prevState, { ...imageInfo, favorited: newFavouriteStatus }]);
            }
        }
    };

    const getIMGHistory = async () => {
        if (user_ID) {
            setIsImageHistoryLoading(true);
            try {
                const response = await apiGatewayClient({
                    path: 'get_image_history',
                    method: 'POST',
                    body: {
                        user_id: user_ID,
                        current_num_photos: imageHistory.length
                    }
                });
                const resObject: object = await response.json();
                Object.keys(resObject).forEach((key) => {
                    groupedHistory[key] = resObject[key].map((img) => ({
                        imageUrl: img.image_url,
                        imageId: img.image_id,
                        favorited: img.favorited,
                        defaultShirtColor: img.default_shirt_color,
                        seed: img.seed,
                        timestamp: moment(img.timestamp).toISOString(),
                        baseImageId: img.base_image_id,
                        rootImageId: img.root_image_id
                    }));
                });
                const shallowHistory: Image[] = Object.values(resObject)
                    .map((imageList) => {
                        const firstImage = imageList[0];
                        return {
                            imageUrl: firstImage.image_url,
                            imageId: firstImage.image_id,
                            favorited: firstImage.favorited,
                            defaultShirtColor: firstImage.default_shirt_color,
                            seed: firstImage.seed,
                            timestamp: moment(firstImage.timestamp).toISOString(),
                            baseImageId: firstImage.base_image_id,
                            rootImageId: firstImage.root_image_id
                        };
                    }).sort((a, b) => moment(b.timestamp).diff(moment(a.timestamp)));
                if (shallowHistory.length > 0) {
                    setImageHistory(prevState => {
                        const newState = [ ...prevState ];
                        shallowHistory.forEach(image => {
                            if (!newState.some(prevImage => prevImage.imageId === image.imageId)) {
                                newState.push(image); // use unshift instead of push
                            }
                        });
                        return newState;
                    });
                }
                setIsImageHistoryLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setIsImageHistoryLoading(false);
            }
        }
    };

    const [selectedModelName, setSelectedModelName] = useState(null);
    const [selectedModelID, setSelectedModelID] = useState();
    const [selectedModelSeed, setSelectedModelSeed] = useState();

    const handleModelClick = model => {
        setSelectedModelID(model.model_id);
        setSelectedModelSeed(model.seed);
    };

    const fetchModels = async () => {
        setIsModelsLoading(true);
        try {
            const response = await apiGatewayClient({
                path: 'get_user_models',
                method: 'GET',
                queryParams: {
                    user_id: user_ID
                }
            });
            const data = await response.json();

            if (Array.isArray(data)) {
                setModels(data);
            } else {
                // eslint-disable-next-line quotes
                console.error("Fetched data isn't an array:", data);
            }
        } catch (error) {
            console.error('Error:', error);
            setModels([]); // Reset models to an empty array in case of error
            setIsModelsLoading(false);
        }
    };

    const getFavoritedIMGs = async () => {
        if (user_ID) {
            setIsFavoritedIMGsLoading(true);
            try {
                const response = await apiGatewayClient({
                    path: 'get_favorited_images',
                    method: 'POST',
                    body: {
                        user_id: user_ID,
                        current_num_photos: favoritedIMGs.length
                    }
                });
                const data = await response.json();
                const imageInfo = data.map(imgDict => ({
                    imageUrl: imgDict.image_url,
                    imageId: imgDict.image_id,
                    favorited: imgDict.favorited,
                    defaultShirtColor: imgDict.default_shirt_color
                }));
                setFavoritedIMGs(prevState => {
                    const newState = [...prevState];
                    imageInfo.forEach((image: Image) => {
                        if (!newState.some((prevImage: Image) => prevImage.imageId === image.imageId)) {
                            newState.push(image); // use unshift instead of push
                        }
                    });
                    return newState;
                });
                setIsFavoritedIMGsLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setIsFavoritedIMGsLoading(false);
            }
        }
    };

    const getDisplayIMG = async () => {
        if (user_ID) {
            try {
                const response = await apiGatewayClient({
                    path: 'get_user_display_picture',
                    method: 'GET',
                    queryParams: {
                        user_id: user_ID
                    }
                });
                const data = await response.json();
                if (data) {
                    const imageInfo = {
                        imageUrl: data.image_url,
                        imageId: data.image_id
                    };
                    setDisplayIMG(imageInfo.imageUrl);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const getDisplayName = async () => {
        if (user_ID) {
            try {
                const response = await apiGatewayClient({
                    path: 'get_user_display_picture',
                    method: 'GET',
                    queryParams: {
                        user_id: user_ID
                    }
                });
                const data = await response.json();
                if (data) {
                    setDisplayName(data);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // for moderation error
    const [modError, setModError] = useState(false);
    const [successMessage, setSuccessMessage] = useState();
    const [noPromptMessage, setNoPromptMessage] = useState();
    const [hovered, setHovered] = useState(false);
    const timerRef = useRef();

    const onErrorClick = () => {
        setModError(false);
    };

    const handleMouseEnter = () => {
        setHovered(true);
        clearTimeout(timerRef.current);
    };

    const handleMouseLeave = () => {
        setHovered(false);
        timerRef.current = setTimeout(() => {
            setModError(false);
            setSuccessMessage(null);
            setNoPromptMessage(null);
        }, 5000) as any;
    };

    const showErrorPrompt = (succesMessage, noPromptMessage) => {
        setSuccessMessage(succesMessage);
        setNoPromptMessage(noPromptMessage);
        setModError(true);

        timerRef.current = setTimeout(() => {
            if (!hovered) {
                setModError(false);
                setSuccessMessage(null);
                setNoPromptMessage(null);
            }
        }, 5000) as any;
    };

    const [isModelImagesLoading, setIsTrainedModelImagesLoading] = useState(false);
    const [modelImages, setModelImages] = useState([]);
    const [TrainedSubjectPrompt, setTrainedSubjectPrompt] = useState('');

    const [availableCredits, setAvailableCredits] = useState(0);

    const fetchCredits = async () => {
        try {
            const response = await apiGatewayClient({
                path: 'get_available_credits',
                method: 'GET',
                queryParams: {
                    user_id: user_ID
                }
            });
            const data = await response.json();
            setAvailableCredits(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getTrainedModelImages = async () => {
        if (availableCredits <= 0) {
            setModError(true);
            setNoPromptMessage('You do not have enough credits to generate an image.' as any);
        } else {
            setIsTrainedModelImagesLoading(true);
            try {
                const enhanced = true;
                const getImagePayload =
                    createGetImagePayload(user_ID, TrainedSubjectPrompt, selectedModelSeed, selectedModelID, enhanced, 
                        false);
                const response = await apiGatewayClient({
                    path: 'get_images',
                    method: 'POST',
                    body: getImagePayload
                });
                const data = await response.json();
                const imageInfo = data.map(imgDict => ({
                    imageUrl: imgDict.image_url,
                    imageId: imgDict.image_id
                }));
                setModelImages(prevState => {
                    const newState = [...prevState];
                    imageInfo.forEach((image: Image) => {
                        if (!newState.some((prevImage: Image) => prevImage.imageId === image.imageId)) {
                            newState.push(image); // use unshift instead of push
                        }
                    });
                    return newState;
                });

                setImageHistory(prevHistory => {
                    const newImages = [];
                    imageInfo.forEach((image: Image) => {
                        if (!prevHistory.some((prevImage: Image) => prevImage.imageId === image.imageId)) {
                            newImages.push(image);
                        }
                    });
                    return [...newImages, ...prevHistory];
                });

                setIsTrainedModelImagesLoading(false);
                fetchCredits();
            } catch (error) {
                console.error('Error:', error);
                setIsTrainedModelImagesLoading(false);
            }
        }
    };

    const resetProfileData = () => {
        imageHistory.length = 0;
        favoritedIMGs.length = 0;
        models.length = 0;
    };

    const getProfileData = async () => {
        await getDisplayIMG();
        await getDisplayName();
        await getIMGHistory();
        await getFavoritedIMGs();
        await fetchModels();
        await fetchCredits();
    };

    useEffect(() => {
        // Use async function inside the useEffect
        const fetchData = async () => {
            resetProfileData();
            await getProfileData();
        };
        fetchData();
    }, [user_ID]);

    const [isLoading, setIsLoading] = useState(false);

    const createScrollHandler = (isFavorite) => {
        return debounce(async (e) => {
            if (isLoading) {
                return;
            }

            let endReached: boolean;

            if (window.innerWidth < 1920) {
                const threshold = 5;
                endReached = e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth + threshold;
            } else {
                const threshold = 5;
                endReached = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + threshold;
            }

            if (endReached) {
                setIsLoading(true);
                if (isFavorite) {
                    await getFavoritedIMGs();
                    setIsFavoritedIMGsLoading(false);
                } else {
                    await getIMGHistory();
                    setIsImageHistoryLoading(false);
                }
                setIsLoading(false);
            }
        }, 150);
    };

    const handleScroll = useCallback(createScrollHandler(false), [getIMGHistory, isLoading]);
    const handleFavScroll = useCallback(createScrollHandler(true), [getFavoritedIMGs, isLoading]);

    const { setSelectedImageURL, setSelectedImageID, setSelectedImageDefaultColor } = useContext(ImageContext);

    const handleImageClick = (image: Image) => {
        setIsShowProduct(true);
        setSelectedImageURL(image.imageUrl);
        setSelectedImageID(image.imageId);
        setSelectedImageDefaultColor(image.defaultShirtColor);
    };

    const debouncedHandleScroll = debounce(handleScroll, 150);
    const debouncedHandleFavScroll = debounce(handleFavScroll, 150);

    useEffect(() => {
        const div = scrollFavDiv.current;
        if (div) {
            div.addEventListener('scroll', debouncedHandleFavScroll);
            // Remove event listener on cleanup
            return () => {
                div.removeEventListener('scroll', debouncedHandleFavScroll);
            };
        }
    }, [debouncedHandleFavScroll]);

    useEffect(() => {
        const div = scrollDiv.current;
        if (div) {
            div.addEventListener('scroll', debouncedHandleScroll);
            return () => {
                div.removeEventListener('scroll', debouncedHandleScroll);
            };
        }
    }, [debouncedHandleScroll]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    // Assign a grid column class based on window width
    const layoutClass = windowWidth < 1920 ? 'grid grid-flow-col' : 'grid grid-cols-3';

    return (
        <div>
            <div className="flex justify-end mr-3">
                {modError && (
                    <ErrorPrompts
                        successMessage={successMessage}
                        noPromptMessage={noPromptMessage}
                        onErrorClick={onErrorClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        errorMessage={undefined}
                    />
                )}
            </div>
            <div className="px-2 sm:md:lg:px-0 flex justify-center items-center mt-[1%] mb-[3%] sm:mb-0 sm:mt-0 h-screen w-full">
                <div className={'px-2 w-full sm:md:lg:w-2/3 h-full sm:h-5/6 border rounded-lg p-4 border-[3px] shadow-xl shadow-gray-600'}>
                    <div className="flex items-center">
                        <div className="flex flex-col sm:md:lg:flex-row items-center">
                            {displayIMG ? (
                                <img
                                    src={displayIMG}
                                    alt="User Display IMG"
                                    className="object-cover h-auto w-1/3 sm:md:lg:w-1/6 rounded-full mr-2"
                                />
                            ) : (
                                <CgProfile size={96} className="mr-2" />
                            )}
                            <span className="my-2 sm:md:lg:my-0 text-xl font-bold">
                                &nbsp;
                                {urlDisplayName ? urlDisplayName : 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="flex justify-between border-b border-gray-200 sm:border-0">
                        <div className="text-sm font-medium text-center text-gray-500 sm:border-b border-gray-200">
                            <ul className="flex  -mb-px">
                                {firebaseUID === searchedUID && (
                                    <li className="mr-1">
                                        <button
                                            onClick={() => handleActiveTab('Image History')}
                                            className={`inline-block p-4 border-b-2 flex items-center ${
                                                activeTab === 'Image History'
                                                    ? 'text-gray-900 border-gray-900 font-bold'
                                                    : 'border-transparent'
                                            } rounded-t-lg hover:text-black ${innerWidth < 395 ? 'text-[13px]' : ''}`}
                                        >
                                            <IoImagesOutline size={25} />
                                            <span className={`sm:w-20 sm:translate-x-0 sm:opacity-100 ${innerWidth < 395 ? `transition-all duration-300 transform overflow-hidden ${activeTab === 'Image History' ? 'w-20 translate-x-0' : 'w-0 -translate-x-1'} inline-block` : ''}`}>
                                                History
                                            </span>
                                        </button>
                                    </li>
                                )}
                                <li className="mr-1">
                                    <button
                                        onClick={() => handleActiveTab('Favourites')}
                                        className={`inline-block p-4 border-b-2 flex items-center ${
                                            activeTab === 'Favourites'
                                                ? 'text-gray-900 border-gray-900 font-bold'
                                                : 'border-transparent'
                                        } rounded-t-lg hover:text-black ${innerWidth < 395 ? 'text-[13px]' : ''}`}
                                    >
                                        <AiOutlineStar size={25}/>
                                        <span className={`sm:w-28 sm:translate-x-0 sm:opacity-100 ${innerWidth < 395 ? `transition-all duration-300 transform overflow-hidden ${activeTab === 'Favourites' ? 'w-28 translate-x-0' : 'w-0 -translate-x-1'} inline-block` : ''}`}>Favourites</span>
                                    </button>
                                </li>
                                {firebaseUID === searchedUID && pathConfig.creatorCustomLines.enabled && (
                                    <li className="mr-1">
                                        <button
                                            onClick={() => handleActiveTab('Trained Models')}
                                            className={`inline-block p-4 border-b-2 flex items-center ${
                                                activeTab === 'Trained Models'
                                                    ? 'text-gray-900 border-gray-900 font-bold'
                                                    : 'border-transparent'
                                            } rounded-t-lg hover:text-black ${innerWidth < 395 ? 'text-[13px]' : ''}`}
                                        >
                                            <AiOutlineRobot size={25}/>
                                            <span className={`sm:w-20 sm:translate-x-0 sm:opacity-100 ${innerWidth < 395 ? `transition-all duration-300 transform overflow-hidden ${activeTab === 'Trained Models' ? 'w-20 translate-x-0' : 'w-0 -translate-x-1'} inline-block` : ''}`}>Models</span>
                                        </button>
                                    </li>
                                )}
                                {firebaseUID === searchedUID && (
                                    <li className="mr-1">
                                        <button
                                            onClick={() => {
                                                handleActiveTab('Settings');
                                                setIsSettingsPage(true);
                                            }}
                                            className={`inline-block p-4 border-b-2 flex items-center ${
                                                activeTab === 'Settings'
                                                    ? 'text-gray-900 border-gray-900 font-bold'
                                                    : 'border-transparent'
                                            } rounded-t-lg hover:text-black  ${innerWidth < 395 ? 'text-[13px]' : ''}`}
                                        >
                                            <AiOutlineSetting size={25}/>
                                            <span className={`sm:w-20 sm:translate-x-0 sm:opacity-100 ${innerWidth < 395 ? `transition-all duration-300 transform overflow-hidden ${activeTab === 'Settings' ? 'w-20 translate-x-0' : 'w-0 -translate-x-1'} inline-block` : ''}`}>Settings</span>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                        {windowWidth >= 640 && activeTab === 'Trained Models' && <TrainedModelsComponent />}
                    </div>
                    <div className="mt-1">
                        {windowWidth < 640 && activeTab === 'Trained Models' && <TrainedModelsComponent />}
                        <div className="flex justify-center">
                            {activeTab === 'Trained Models' && (
                                <div className="w-3/4">
                                    <div className="top-0 flex flex-col items-center rounded-r-lg rounded-b-lg w-full template-search">
                                        <div className="flex flex-col sm:md:lg:flex-row py-4 px-4 sm:md:lg:pl-4 w-full overflow-x-disabled sm:md:lg:overflow-x-hidden">
                                            <div className="flex w-full min-w-max sm:md:lg:min-w-0 scrollbar-div">
                                                <div className="relative w-full">
                                                    <textarea
                                                        id={'Subject'}
                                                        autoComplete="off"
                                                        key={'Subject'}
                                                        name={'Subject'}
                                                        maxLength={220}
                                                        rows={3}
                                                        value={TrainedSubjectPrompt}
                                                        className="peer placeholder-transparent overflow-hidden resize-none h-full w-full p-4 rounded-lg border border-gray-600 text-black"
                                                        placeholder="Subject"
                                                        onChange={event => setTrainedSubjectPrompt(event.target.value)}
                                                        onKeyDown={event => {
                                                            if (event.key === 'Enter') {
                                                                event.preventDefault();
                                                                getTrainedModelImages();
                                                            }
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor={'subject'}
                                                        className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[1.3rem] peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                                                    >
                                                        Subject
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex space-x-4 sm:md:lg:space-x-0 sm:md:lg:flex-col justify-center items-center sm:md:lg:h-28 px-4 pt-3 sm:md:lg:pt-0 sm:md:lg:space-y-2">
                                                <button
                                                    type="submit"
                                                    // Disable the button while loading
                                                    disabled={
                                                        isModelImagesLoading &&
                                                        !selectedModelName &&
                                                        !TrainedSubjectPrompt
                                                    }
                                                    className={`${
                                                        !TrainedSubjectPrompt && !selectedModelName
                                                            ? 'opacity-50 cursor-not-allowed'
                                                            : ''
                                                    } text-white border border-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-3 bg-[#0284c7] hover:bg-[#0369a1] focus:ring-[#0369a1] `}
                                                    onClick={event => getTrainedModelImages()}
                                                >
                                                    Generate
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className={`${layoutClass} gap-2 overflow-x-auto ${
                                                modelImages.length > 3
                                                    ? `${windowWidth < 1920 ? 'h-[20.7rem]' : 'sm:h-[25rem]'}`
                                                    : 'h-[20.7rem] sm:h-[19.15rem]'
                                            } py-1 w-full rounded-lg scrollbar-div px-4 pb-12`}
                                        >
                                            {modelImages.map((imageInfo: Image, index: number) => (
                                                <div
                                                    key={`${imageInfo?.imageId || index}-${
                                                        imageInfo.favorited
                                                    }-${displayImageId}`}
                                                    className={'w-[20.5rem] sm:md:lg:w-[18.5rem] flex relative '}
                                                >
                                                    {isModelImagesLoading && index === 0 ? (
                                                        <div className="flex justify-center items-center w-full h-[17rem] mx-2">
                                                            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-2"></div>
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        <div className='hover:opacity-70'>
                                                            {!isModelImagesLoading && firebaseUID === searchedUID && (
                                                                <div>
                                                                    <button
                                                                        className={`absolute top-3 right-9 ${
                                                                            isShowProduct ? 'z-5' : 'z-20'
                                                                        } text-white`}
                                                                        onClick={() => handleFavouriteClick(imageInfo)}
                                                                    >
                                                                        {imageInfo.favorited ? (
                                                                            <div className='relative'>
                                                                                <AiFillStar size={33} className='absolute text-black -ml-[3.5px] -mt-[3.65px]'/>
                                                                                <AiFillStar size={25} className='absolute text-yellow-300'/>
                                                                            </div>
                                                                        ) : (
                                                                            <div className='relative'>
                                                                                <AiFillStar size={33} className='absolute text-black -ml-[3.5px] -mt-[3.65px]'/>
                                                                                <AiOutlineStar size={25} className='absolute text-white'/>
                                                                            </div>
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        className={`absolute top-3 left-3 ${
                                                                            isShowProduct ? 'z-5' : 'z-20'
                                                                        } text-white bg-black rounded-md p-1 bg-opacity-30 hover:bg-opacity-90`}
                                                                        onClick={() =>
                                                                            handleSettingDisplayIMG(imageInfo)
                                                                        }
                                                                    >
                                                                        <div className='bg-black rounded-sm px-[1px]'>
                                                                            <RiProfileLine size={25} />
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            )}
                                                            <img
                                                                alt="selectedImage"
                                                                onClick={() => handleImageClick(imageInfo)}
                                                                className={
                                                                    'border rounded-md cursor-pointer h-auto object-cover'
                                                                }
                                                                src={imageInfo.imageUrl}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'Image History' &&
                                (isImageHistoryLoading && !imageHistory.length ? (
                                    <div className="flex justify-center items-center w-full h-[17rem] mx-2">
                                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-2"></div>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <div
                                        ref={scrollDiv}
                                        className={`${layoutClass} gap-2 overflow-x-auto ${
                                            imageHistory.length > 3
                                                ? `${windowWidth < 1920 ? 'h-[25rem]' : 'sm:h-[35.3rem]'}`
                                                : 'h-[20.7rem] sm:h-[18.15rem]'
                                        } py-1 w-fit rounded-lg scrollbar-div px-4 pb-12`}
                                    >
                                        {imageHistory.map((imageInfo: Image, index: number) => (
                                            <div
                                                key={`${imageInfo?.imageId || index}-${
                                                    imageInfo.favorited
                                                }-${displayImageId}`}
                                                className={`${windowWidth < 1920 ? 'w-[20.5rem] h-[20rem]' : 'w-[20.5rem]'} sm:md:lg:w-[18.5rem] flex relative hover:opacity-70`}
                                            >
                                                {!isImageHistoryLoading && firebaseUID === searchedUID && (
                                                    <div>
                                                        <button
                                                            className={`absolute top-3 right-9 ${
                                                                isShowProduct ? 'z-5' : 'z-20'
                                                            } text-white`}
                                                            onClick={() => handleFavouriteClick(imageInfo)}
                                                        >
                                                            {imageInfo.favorited ? (
                                                                <div className='relative'>
                                                                    <AiFillStar size={33} className='absolute text-black -ml-[3.5px] -mt-[3.65px]'/>
                                                                    <AiFillStar size={25} className='absolute text-yellow-300'/>
                                                                </div>
                                                            ) : (
                                                                <div className='relative'>
                                                                    <AiOutlineStar size={33} className='absolute text-black -ml-[3.5px] -mt-[3.65px]'/>
                                                                    <AiFillStar size={25} className='absolute text-white'/>
                                                                </div>
                                                            )}
                                                        </button>
                                                        <button
                                                            className={`absolute top-3 left-3  ${
                                                                isShowProduct ? 'z-5' : 'z-20'
                                                            } text-white`}
                                                            onClick={() => handleSettingDisplayIMG(imageInfo)}
                                                        >
                                                            <div className='bg-black rounded-sm px-[1px]'>
                                                                <RiProfileLine size={25} />
                                                            </div>
                                                        </button>
                                                    </div>
                                                )}
                                                <div>
                                                    <div className="relative">
                                                        <img
                                                            onClick={() => handleImageClick(imageInfo)}
                                                            alt="Generated Image"
                                                            className={'cursor-pointer h-auto w-full object-cover bg-black bg-opacity-0 hover:opacity-60 shadow-xl shadow-gray-900'}
                                                            src={imageInfo.imageUrl}
                                                        />
                                                        {getNumberOfRelatedImages(
                                                            imageInfo.rootImageId,
                                                            imageInfo.imageId) > 0 && (<button
                                                            className="shadow-md shadow-gray-700 text-white text-md border-2 border-slate-600 focus:ring-2 focus:ring-[#0369a1] focus:outline-none
                                                                   font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 w-[4rem] h-[2rem] whitespace-nowrap absolute bottom-0 right-0 m-4"
                                                            onClick={() => {
                                                                showRelatedImagesOnClick(imageInfo.rootImageId, index);
                                                                setExpandedIndex(index);
                                                            }}
                                                        >
                                                            <div
                                                                className={'flex flex-row justify-center items-center gap-1'}>
                                                                <span>
                                                                    +{getNumberOfRelatedImages(
                                                                        imageInfo.rootImageId,
                                                                        imageInfo.imageId)}
                                                                </span>
                                                                {
                                                                    (showRelatedImages && expandedIndex === index) ?
                                                                        <FaAngleUp></FaAngleUp> :
                                                                        <FaAngleDown></FaAngleDown>
                                                                }
                                                            </div>
                                                        </button>)}
                                                    </div>
                                                    {(showRelatedImages && expandedIndex === index) && (
                                                        <div ref={verticalScrollDiv} className={'h-[25rem] overflow-y-auto overflow-x-hidden scrollbar-div'}>
                                                            {relatedImages.map((imageInfo, index) => (
                                                                <div key={index}
                                                                    className="flex-shrink-0 px-2 w-[17.25rem] mt-2">
                                                                    <Link to="productCard" smooth={true} duration={500}>
                                                                        <img
                                                                            onClick={() => handleImageClick(imageInfo)}
                                                                            alt="Generated Image"
                                                                            className={'cursor-pointer h-auto w-full object-cover bg-black bg-opacity-0 hover:opacity-60'}
                                                                            src={imageInfo.imageUrl}
                                                                        />
                                                                    </Link>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                        </div>
                        <div className="flex justify-center">
                            {activeTab === 'Favourites' && (
                                <div
                                    ref={scrollFavDiv}
                                    className={`${layoutClass} gap-2 overflow-x-auto ${
                                        favoritedIMGs.length > 3
                                            ? `${windowWidth < 1920 ? 'h-[25rem]' : 'sm:h-[35.3rem]'}`
                                            : 'h-[20.7rem] sm:h-[18.15rem]'
                                    } py-1 w-fit rounded-lg scrollbar-div px-4 pb-12`}
                                >
                                    {favoritedIMGs.map((imageInfo: Image, index: number) => (
                                        <div
                                            key={`${imageInfo?.imageId || index}-${imageInfo.favorited}`}
                                            className={`${windowWidth < 1920 ? 'w-[20.5rem] h-[20rem]' : 'w-[20.5rem]'} sm:md:lg:w-[18.5rem] flex relative hover:opacity-70`}
                                        >
                                            {!isFavoritedIMGsLoading && firebaseUID === searchedUID && (
                                                <button
                                                    className={`absolute top-3 right-9 ${
                                                        isShowProduct ? 'z-5' : 'z-20'
                                                    } text-white`}
                                                    onClick={() => handleFavouriteClick(imageInfo)}
                                                >
                                                    {imageInfo.favorited ? (
                                                        <div className='relative'>
                                                            <AiFillStar size={33} className='absolute text-black -ml-[3.5px] -mt-[3.65px]'/>
                                                            <AiFillStar size={25} className='absolute text-yellow-300'/>
                                                        </div>
                                                    ) : (
                                                        <div className='relative'>
                                                            <AiFillStar size={33} className='absolute text-black -ml-[3.5px] -mt-[3.65px]'/>
                                                            <AiOutlineStar size={25} className='absolute text-white'/>
                                                        </div>
                                                    )}
                                                </button>
                                            )}
                                            {isFavoritedIMGsLoading && !favoritedIMGs.length ? (
                                                <div className="flex justify-center items-center w-full h-[17rem] mx-2">
                                                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-2"></div>
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <img
                                                    alt="selectedImage"
                                                    className={
                                                        'border rounded-md cursor-pointer h-auto object-cover shadow-xl shadow-gray-600'
                                                    }
                                                    src={imageInfo.imageUrl}
                                                    onClick={() => handleImageClick(imageInfo)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {activeTab === 'Settings' && (
                            <div className="my-4 space-y-3">
                                <div className="flex items-center space-x-4">
                                    <div className="relative">
                                        <input
                                            maxLength={16}
                                            id={'displayName'}
                                            autoComplete="off"
                                            key={'displayName'}
                                            name={'displayName'}
                                            className="peer flex-none w-full sm:md:lg:w-[16.7rem] py-2 px-4 rounded-lg shadow-md shadow-gray-900 text-black focus:ring-gray-500 border-2"
                                            placeholder={realDisplayName}
                                            onChange={event => setUserDisplayName(event.target.value)}
                                        />
                                        <label
                                            htmlFor={'displayName'}
                                            className="absolute text-sm text-gray-500 top-2 z-10 bg-white px-2 peer-focus:px-2 peer-focus:text-gray-600 scale-75 top-[-0.5rem] -left-2"
                                        >
                                            Display Name
                                        </label>
                                    </div>
                                    <button
                                        onClick={handleSetDisplayName}
                                        type="button"
                                        className="text-white border border-gray-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-3 shadow-md shadow-gray-900 bg-neutral-900 hover:bg-neutral-700"
                                        id="menu-button"
                                        aria-expanded="true"
                                        aria-haspopup="true"
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    {changeDisplayModal && (
                        <div className="border border-gray-900 border-2 shadow-inner shadow-gray-900 bg-white fixed top-[40%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 z-50 w-2/3 sm:w-1/4 h-[15%] flex flex-col justify-center items-center space-y-2 rounded-lg">
                            <p className="text-center w-full font-bold">Are you sure you want to change your display image?</p>
                            <div className="flex space-x-8 text-gray-500 ">
                                <button
                                    onClick={() => handleModalYes(currentDisplayImageInfo)}
                                    className="font-bold text-white border border-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-3 shadow-md shadow-gray-900 bg-neutral-900 hover:bg-neutral-700"
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={handleModalNo}
                                    className="font-bold text-white border border-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-3 shadow-md shadow-gray-900 bg-neutral-500 hover:bg-neutral-400"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
