import React, { createContext, useEffect, useState } from 'react';

export const CartContext = createContext({
    items: [],
    getProductQuantity: () => {},
    addOneToCart: (priceId, product_id, size, color, base64, imageId, product_images) => {},
    removeOneFromCart: (priceId, product_id, size, color, base64, imageId, product_images) => {},
    deleteFromCart: (priceId, product_id, size, color, base64, imageId, product_images) => {},
    getTotalCost: (products) => {}
});

export function CartProvider({ children }) {
    const [cartProducts, setCartProducts] = useState([]);

    function getProductQuantity(
        priceId: string, product_id: string, size: string, 
        color: string, base64: string, imageId: string) {
        const quantity = cartProducts.find(
            product =>
                product.price_id === priceId &&
                product.product_id === product_id &&
                product.size === size &&
                product.color === color &&
                product.base64 === base64 &&
                product.image_id === imageId 
        )?.quantity;
        if (quantity === undefined) {
            return 0;
        }
        return quantity;
    }

    function addOneToCart(
        priceId: string, product_id: string, size: string, 
        color: string, base64: string, imageId: string, productImages: any[]) {
        const quantity = getProductQuantity(priceId, product_id, size, color, base64, imageId);
        if (quantity === 0) {
            setCartProducts([
                ...cartProducts,
                {
                    price_id: priceId,
                    product_id: product_id,
                    quantity: 1,
                    color: color,
                    size: size,
                    base64: base64,
                    image_id: imageId,
                    product_images: productImages
                }
            ]);
        } else {
            setCartProducts(
                cartProducts.map(product =>
                    product.price_id === priceId &&
                    product.size === size &&
                    product.color === color &&
                    product.base64 === base64 &&
                    product.image_id === imageId &&
                    product.product_images === productImages
                        ? { ...product, quantity: product.quantity + 1 }
                        : product
                )
            );
        }
    }

    useEffect(() => {
        console.log(cartProducts);
    }, [cartProducts]);

    function removeOneFromCart(
        priceId: string, product_id: string, size: string, 
        color: string, base64: string, imageId: string, productImages: any[]) {
        const quantity = getProductQuantity(priceId, product_id, size, color, base64, imageId);

        if (quantity === 1) {
            deleteFromCart(priceId, product_id, size, color, base64, imageId, productImages);
        } else {
            setCartProducts(
                cartProducts.map(product =>
                    product.price_id === priceId &&
                    product.product_id === product_id &&
                    product.size === size &&
                    product.color === color &&
                    product.base64 === base64 &&
                    product.image_id === imageId &&
                    product.product_images === productImages
                        ? { ...product, quantity: product.quantity - 1 }
                        : product
                )
            );
        }
    }

    function deleteFromCart(price_id, product_id, size, color, base64, image_id, productImages: any[]) {
        setCartProducts(cartProducts =>
            cartProducts.filter(
                currentProduct =>
                    !(
                        currentProduct.price_id === price_id &&
                        currentProduct.product_id === product_id &&
                        currentProduct.size === size &&
                        currentProduct.color === color &&
                        currentProduct.base64 === base64 &&
                        currentProduct.image_id === image_id &&
                        currentProduct.product_images === productImages
                    )
            )
        );
    }

    function getTotalCost(products) {
        let totalCost = 0;
        // eslint-disable-next-line
        cartProducts.forEach(cartItem => {
            // Find the product in the products array that matches the cartItem's price_id
            const product = products.find(product => product.price_id === cartItem.price_id);
            if (product) {
                totalCost += product.price * cartItem.quantity;
            }
        });
        return totalCost;
    }

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost
    };

    return <CartContext.Provider value={contextValue as any}>{children}</CartContext.Provider>;
}
