import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { CartContext } from '../contexts/CartContext';
import { UserContext } from '../contexts/UserContext';
import SingleCartProduct from './SingleCartProduct';
import { apiGatewayClient } from './utils';
import { useGlobalProducts } from 'contexts/ProductsStore';

const ShoppingCart = ({ setIsShowCart, handleClick }) => {
    const cart = useContext(CartContext);
    const [slideIn, setSlideIn] = useState(false);
    const cartContentRef = useRef(null);
    const { user_ID } = useContext(UserContext);
    const [isHovered, setIsHovered] = useState(false);
    const products = useGlobalProducts();

    useEffect(() => {
        setSlideIn(true);
    }, []);

    const handleCheckoutClick = async () => {
        handleClick('ShoppingCart', 'Checkout Button', '');
        if (cart.items.length >= 1) {
            await apiGatewayClient({
                path: 'checkout',
                method: 'POST',
                body: {
                    user_id: user_ID,
                    cart_items: cart.items
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(response => {
                    if (response.checkout_url) {
                        window.location.assign(response.checkout_url); // forwarding user to stripe
                        window.addEventListener('beforeunload', () => checkPaymentStatus(response.id));
                    }
                })
                .catch(e => {
                    console.error('There was a problem with the fetch operation: ' + e.message);
                });
        }
    };

    const onBackClick = () => {
        setSlideIn(false);
        setTimeout(() => {
            handleClick('ShoppingCart', 'Back Button', '');
            setIsShowCart(false);
        }, 700);
    };

    const handleOuterClick = e => {
        handleClick('ShoppingCart', 'Outer Shopping Cart', '');
        if (!cartContentRef.current.contains(e.target)) {
            onBackClick();
        }
    };

    const checkPaymentStatus = async (sessionId: string) => {
        await apiGatewayClient({
            path: 'checkout-success',
            method: 'GET',
            queryParams: {
                session_id: sessionId
            }
        });
    };

    const [startX, setStartX] = useState(null);

    const handleTouchStart = e => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchMove = e => {
        if (startX && e.touches[0].clientX - startX > 100) {
            // adjust this value to the sensitivity you want
            // logic to close the sidebar/cart
            setTimeout(() => {
                handleClick('ShoppingCart', 'Back Button', '');
                setIsShowCart(false);
            }, 700);
            setSlideIn(false); // Assuming setSlideIn will close the sidebar
            setStartX(null); // Reset startX to avoid multiple triggers
        }
    };

    useEffect(() => {
        // Add the scrollbar-screen class to the html element when the component mounts
        document.documentElement.classList.add('scrollbar-checkOut');

        // Remove the scrollbar-screen class when the component is unmounted
        return () => {
            document.documentElement.classList.remove('scrollbar-checkOut');
        };
    }, []);

    return (
        <div
            className="w-full h-full z-50 bg-black bg-opacity-50 top-0 overflow-y-auto overflow-x-hidden fixed sticky-0"
            id="chec-div"
            onClick={handleOuterClick}
        >
            <div className="scrollbar-checkOut h-full overflow-y-auto">
                <div
                    ref={cartContentRef}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    className={`absolute z-10 right-0 h-full overflow-x-hidden transition-all ease-in-out duration-700  ${
                        slideIn ? 'translate-x-0' : 'translate-x-full'
                    } w-full lg:w-1/4 md:w-2/5 sm:w-full`}
                    id="checkout"
                    // Add this line to set the width of the checkout div
                >
                    <div className="bg-white h-full overflow-x-hidden">
                        <div className="flex flex-col h-screen px-4 pt-4 justify-between overflow-y-auto">
                            <div>
                                <div
                                    className="flex items-center text-gray-500 hover:text-gray-600 cursor-pointer"
                                    onClick={onBackClick}
                                >
                                    <svg
                                        className="icon icon-tabler icon-tabler-chevron-left"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                        ></path>
                                    </svg>
                                    <svg
                                        className="icon icon-tabler icon-tabler-chevron-left"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                        ></path>
                                    </svg>
                                    <svg
                                        className="icon icon-tabler icon-tabler-chevron-left"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                        ></path>
                                    </svg>
                                </div>
                                <p className="text-5xl font-black leading-10 text-black pt-3 flex justify-center">Shopping Cart</p>
                                <div className="flex flex-col items-center mt-14 py-8 border-t border-gray-200">
                                    {cart.items.length > 0 ? (
                                        cart.items.map((currentProduct, idx) => (
                                            <SingleCartProduct
                                                handleClick={handleClick}
                                                key={idx}
                                                priceId={currentProduct.price_id}
                                                product_id={currentProduct.product_id}
                                                quantity={currentProduct.quantity}
                                                size={currentProduct.size}
                                                color={currentProduct.color}
                                                base64={currentProduct.base64} // Add this line
                                                imageId={currentProduct.image_id}
                                                selectedProduct={currentProduct}
                                            ></SingleCartProduct>
                                        ))
                                    ) : (
                                        <div className="flex items-center justify-center h-full w-full font-bold">
                                            <p className="text-black">There Are Currently No Items In The Cart</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="w-full bg-white h-fit">
                                <div className="flex flex-col px-4 py-10 justify-between overflow-y-auto border-t border-gray-200">
                                    <div>
                                        <p className="text-4xl font-black leading-9 text-black">Summary</p>
                                        <div className=" flex justify-between pt-16">
                                            <p className="text-base leading-none text-black">Subtotal</p>
                                            <p className="text-base leading-none text-black">
                                                ${(cart.getTotalCost(products) as any).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="py-2 relative">
                                            <button
                                                onClick={handleCheckoutClick}
                                                onMouseEnter={() => {
                                                    if (cart.items.length < 1) {
                                                        setIsHovered(true);
                                                    }
                                                }}
                                                onMouseLeave={() => setIsHovered(false)}
                                                className={`${
                                                    cart.items.length < 1 ? 'opacity-50 cursor-not-allowed' : ''
                                                } shadow-md shadow-gray-900 bg-neutral-900 hover:bg-neutral-700 text-white font-bold leading-none w-full py-5`}
                                            >
                                                <div className="flex justify-center items-center">
                                                    <MdOutlineShoppingCart size={20}></MdOutlineShoppingCart><span className="ml-2">Checkout</span>
                                                </div>
                                            </button>
                                            {isHovered && (
                                                <div className="absolute -top-8 left-5 z-10 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700">
                                                    Please add an item to your cart to checkout
                                                    <div data-popper-arrow></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShoppingCart;
