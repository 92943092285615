const devBackendEndpoint = 'https://unbranded-ai-api-gateway-wezrhxg.uc.gateway.dev/';
const prodBackendEndpoint = 'https://unbranded-ai-api-gateway-prod-wezrhxg.uc.gateway.dev/';
const devApiKey = 'AIzaSyB66q0mz9J0qiJjmBiCKntgwWjXrAfPeuA';
const prodApiKey = 'AIzaSyAJ27uNn_FsKORITKNSfQqNrPSzmW07fLs';

export const createGetImagePayload =
    (userId: string, searchText: string, seed: string, modelId: string, enhanced: boolean, 
        remove_background: boolean) => {
        return {
            user_id: userId,
            prompt: searchText,
            seed: seed ?? undefined,
            enhance: enhanced ?? undefined,
            model_id: modelId ?? undefined,
            remove_background: remove_background ?? undefined
        };
    };

export enum Domains {
    DEV = 'dev',
    PROD = 'prod'
}
export const DomainMap: Record<Domains, string[]> = {
    dev: [ 'localhost',  'starfish-app-zq3ny.ondigitalocean.app' ],
    prod: [ 'www.unbrandedai.com' ]
};

export function getCurrentDomain(): string | undefined {
    const domain: string = window.location.hostname;
    if (DomainMap.dev.includes(domain)) {
        return Domains.DEV;
    } else if (DomainMap.prod.includes(domain)) {
        return Domains.PROD;
    }
}

export function getBackendEndpoint(): string {
    return getCurrentDomain() === Domains.PROD ? prodBackendEndpoint : devBackendEndpoint;
}

export async function apiGatewayClient(
    params: {
        path: string,
        method: string,
        queryParams?: Record<string, string | boolean | number>,
        body?: Record<string, unknown>
    }): Promise<Response> {

    const requestInit: RequestInit = {
        method: params.method,
        headers: {
            'Accept': 'application/json',
            'X-Api-Key': getCurrentDomain() === Domains.PROD ? prodApiKey : devApiKey,
            'Content-Type': 'application/json'
        }
    };

    let queryParams = '';
    if (params.queryParams) {
        queryParams += '?' + Object.keys(params.queryParams)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params.queryParams[k]))
            .join('&');
    }
    if (params.body) {
        requestInit.body = JSON.stringify(params.body);
    }
    return fetch(`${getBackendEndpoint() + params.path + queryParams}`, requestInit);
}

export async function uploadToS3(preSignedUrl: string, file: File) {
    return await fetch(preSignedUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type
        },
        body: file
    });
}

export function isUsingMobile(): boolean {
    return /Mobi|Android|iPhone/i.test(navigator.userAgent);
}