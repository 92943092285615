import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
const firebaseConfig = {
    apiKey: 'AIzaSyCFkNNCVfv0x1WAvYGTgs7dHtNN0Rdn4ek',
    authDomain: 'unbrandedai.firebaseapp.com',
    projectId: 'unbrandedai',
    storageBucket: 'unbrandedai.appspot.com',
    messagingSenderId: '928653317478',
    appId: '1:928653317478:web:c3b9000be7141f97a224a7',
    measurementId: 'G-ECSJJNYD6G',
    databaseURL: 'https://unbrandedai-default-rtdb.firebaseio.com/'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const provider = new GoogleAuthProvider();

export { auth, database, provider };

