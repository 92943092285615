import React, { useEffect, useState, useContext } from 'react';
import LOGO from '../images/UnbrandedAI_logo.jpg';
import { useNavigate } from 'react-router-dom';
import { GoSignOut, GoSignIn } from 'react-icons/go';
import { UserContext } from '../contexts/UserContext';
import { auth } from './GoogleSignIn/config';
import { BsChatRightTextFill, BsPersonCircle } from 'react-icons/bs';
import { AiFillHome } from 'react-icons/ai';
import { FaHistory } from 'react-icons/fa';
import { apiGatewayClient } from './utils';
import { pathConfig } from 'models/sideBarModels';

const SideBar = () => {
    const [isHovered, setIsHovered] = useState(sessionStorage.getItem('isSidebarHovered') === 'true');
    const navigate = useNavigate();
    const [isSignedOut, setIsSignedOut] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const { user_ID, setFirebaseUID, userIP, realDisplayName } = useContext(UserContext);
    const [displayIMG, setDisplayIMG] = useState();

    const getDisplayIMG = async () => {
        if (user_ID) {
            try {
                const response = await apiGatewayClient({
                    path: 'get_user_display_picture',
                    method: 'GET',
                    queryParams: {
                        user_id: user_ID
                    }
                });
                const data = await response.json();
                if (data) {
                    const imageINFO = {
                        image_url: data.image_url,
                        id: data.image_id
                    };
                    setDisplayIMG(imageINFO.image_url);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleSignOut = () => {
        auth.signOut()
            .then(() => {
                // Sign-out successful.
                localStorage.clear();
                sessionStorage.clear();
                setIsSignedOut(true);
                setFirebaseUID(null);
                navigate('/');
            })
            .catch(error => {
                console.error('Error', error);
            });
    };
    const handleSignIn = () => {
        navigate('/signIn'); // Redirect to login page after sign out
    };

    useEffect(() => {
        if (user_ID === userIP) {
            setIsSignedOut(true);
            setIsDisabled(true);
        } else {
            setIsSignedOut(false);
            setIsDisabled(false);
            getDisplayIMG();
        }
    }, [user_ID]);

    const openPopup = () => {
        window.open('/feedback', '_blank', 'width=600,height=637');
    };

    const setIsHoveredState = value => {
        setIsHovered(value);
        sessionStorage.setItem('isSidebarHovered', value);
    };

    const [showOrderDataTooltip, setShowOrderDataTooltip] = useState(false);
    const [showCustomLinesTooltip, setShowCustomLinesTooltip] = useState(false);

    return (
        <div className="hidden lg:flex fixed min-h-screen bg-white z-50 ">
            <div
                className="duration-300 min-h-screen w-[3.5rem] overflow-hidden border-r hover:w-60"
                onMouseEnter={() => setIsHoveredState(true)}
                onMouseLeave={() => setIsHoveredState(false)}
            >
                <div className="flex h-screen flex-col justify-between pt-2 relative">
                    <div className="">
                        <button className="flex items-center mt-3" onClick={() => navigate('/')}>
                            <img src={LOGO} className={`px-1 h-auto ${isHovered ? 'w-14' : 'w-14'} `} alt="LOGO" />
                            {isHovered && (
                                <h1 className="text-xl font-bold text-black ml-2 whitespace-nowrap">Unbranded AI</h1>
                            )}
                        </button>
                        <div className="relative flex-grow mt-6 ">
                            <ul className="space-y-2  tracking-wide">
                                <li className="min-w-max">
                                    <button
                                        onClick={() => navigate('/')}
                                        className="group flex items-center space-x-5 pl-4 pr-[8.5rem] py-3 text-black hover:bg-[#f3f4f6] hover:text-[#0284c7]"
                                    >
                                        <AiFillHome size={20} />
                                        <span className="group-hover:text-[#0284c7]">Home</span>
                                    </button>
                                </li>
                                <li className="min-w-max">
                                    {!isDisabled && pathConfig.profile.enabled && (
                                        <button
                                            onClick={() => navigate(`/profile/${realDisplayName}`)}
                                            className="group flex items-center space-x-5 pl-4 pr-[8.5rem] py-3 text-black hover:bg-[#f3f4f6] hover:text-[#0284c7]"
                                        >
                                            {displayIMG ? (
                                                <img src={displayIMG} alt="User Display IMG" className="w-5 h-5 rounded-full" />
                                            ) : (
                                                <BsPersonCircle size={20} className={'mb-[-0.3rem]'}/>
                                            )}
                                            <span className="group-hover:text-[#0284c7]">Profile</span>
                                        </button>
                                    )}
                                </li>
                                <li className="min-w-max">
                                    {!isDisabled && pathConfig.profile.enabled && (
                                        <button
                                            onClick={() => navigate('/orders')}
                                            className="group flex items-center space-x-5 pl-4 pr-[8.5rem] py-3 text-black hover:bg-[#f3f4f6] hover:text-[#0284c7]"
                                        >
                                            <FaHistory size={20} />
                                            <span className="group-hover:text-[#0284c7]">Orders</span>
                                        </button>
                                    )}
                                </li>
                                {pathConfig.creatorCustomLines.enabled && (
                                    <li onMouseEnter={() => setShowCustomLinesTooltip(true)}
                                        onMouseLeave={() => setShowCustomLinesTooltip(false)}
                                        className="min-w-max">
                                        {!isDisabled && (
                                            <button
                                                disabled={!pathConfig.creatorCustomLines.enabled}
                                                onClick={() => navigate('/creator-custom-lines')}
                                                className={`group flex items-center space-x-5 pl-4 pr-8 py-3 text-black ${!pathConfig.creatorCustomLines.enabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#f3f4f6]'}`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className={`h-5 w-5 ${!pathConfig.creatorCustomLines.enabled ? 'opacity-50' : 'group-hover:text-cyan-300'}`}
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        className="fill-current text-black group-hover:text-cyan-300"
                                                        fillRule="evenodd"
                                                        d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
                                                        clipRule="evenodd"
                                                    />
                                                    <path
                                                        className={`fill-current text-black ${!pathConfig.creatorCustomLines.enabled ? 'opacity-50' : 'group-hover:text-cyan-600'}`}
                                                        d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"
                                                    />
                                                </svg>
                                                <div
                                                    className={`absolute ml-[5rem] bg-black text-white px-2 py-1 rounded ${showCustomLinesTooltip ? 'block' : 'hidden'}`}>
                                                Coming Soon
                                                </div>
                                                <span
                                                    className={`group-hover:text-[#0284c7] ${!pathConfig.creatorCustomLines.enabled ? 'opacity-50' : ''}`}>Create Custom Lines</span>
                                            </button>
                                        )}
                                    </li>)}

                                <li className="min-w-max">
                                    {pathConfig.explore.enabled && (
                                        <button
                                            onClick={() => navigate('/explore')}
                                            className="group flex items-center space-x-5  pl-4 pr-32 py-3 text-black hover:bg-[#f3f4f6]"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    className="fill-current text-black group-hover:text-cyan-600"
                                                    fillRule="evenodd"
                                                    d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                                                    clipRule="evenodd"
                                                />
                                                <path
                                                    className="fill-current text-black group-hover:text-cyan-300"
                                                    d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"
                                                />
                                            </svg>
                                            <span className="group-hover:text-[#0284c7]">Explore</span>
                                        </button>
                                    )}
                                </li>
                                {pathConfig.orderData.enabled && (<li onMouseEnter={() => setShowOrderDataTooltip(true)}
                                    onMouseLeave={() => setShowOrderDataTooltip(false)}
                                    className="min-w-max">
                                    {!isDisabled && (
                                        <button
                                            disabled={!pathConfig.orderData.enabled}
                                            className={`group flex items-center space-x-5 pl-4 pr-8 py-3 text-black ${!pathConfig.creatorCustomLines.enabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#f3f4f6]'}`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className={`h-5 w-5 ${!pathConfig.creatorCustomLines.enabled ? 'opacity-50' : 'group-hover:text-cyan-300'}`}
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    className="fill-current text-black group-hover:text-cyan-600"
                                                    d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                                                />
                                                <path
                                                    className={`fill-current text-black ${!pathConfig.creatorCustomLines.enabled ? 'opacity-50' : 'group-hover:text-cyan-600'}`}
                                                    d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"
                                                />
                                            </svg>
                                            <div
                                                className={`absolute ml-[5rem] bg-black text-white px-2 py-1 rounded ${showOrderDataTooltip ? 'block' : 'hidden'}`}>
                                                Coming Soon!
                                            </div>
                                            <span
                                                className={`group-hover:text-[#0284c7] ${!pathConfig.creatorCustomLines.enabled ? 'opacity-50' : ''}`}>Order Data</span>
                                        </button>
                                    )}
                                </li>)}
                                <li className="min-w-max">
                                    <button
                                        onClick={openPopup}
                                        className="group flex items-center space-x-5  pl-4 pr-28 py-3 text-black hover:bg-[#f3f4f6]"
                                    >
                                        <div className="group-hover:text-[#0284c7]">
                                            <BsChatRightTextFill size={20} />
                                        </div>
                                        <span className="group-hover:text-[#0284c7]">Feedback</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ul>
                        {isSignedOut ? (
                            <li className="min-w-max">
                                <button
                                    onClick={handleSignIn}
                                    className={`group flex items-center space-x-5  pl-5 pr-32 py-3 text-black ${
                                        isHovered ? 'bg-gradient-to-r from-[#0284c7] to-[#0369a1] text-white' : ''
                                    } duration-200`}
                                >
                                    <GoSignIn />
                                    <span className={`${isHovered ? 'pl-0' : 'pl-8'} `}>Sign In</span>
                                </button>
                            </li>
                        ) : (
                            <li className="min-w-max">
                                <button
                                    onClick={handleSignOut}
                                    className={`group flex items-center space-x-5  pl-5 pr-28 py-3 text-black ${
                                        isHovered ? 'bg-red-500 hover:bg-red-400 text-white' : ''
                                    } duration-200`}
                                >
                                    <GoSignOut />
                                    <span className={`${isHovered ? 'pl-0' : 'pl-8'} `}>Sign Out</span>
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SideBar;
