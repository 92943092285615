import React, { useState } from 'react';
import sizeGuideMetric from '../images/sizeGuideMetric.png';
import sizeGuideImperial from '../images/sizeGuideImperial.png';

const SizeGuide = ({ showSizeGuide, setShowSizeGuide }) => {
    const [sizeGuideUnit, setSizeGuideUnit] = useState('imperial');
    const sizes = ['S', 'M', 'L', 'XL', '2XL', '3XL'];
    const imperialLengthValues = ['28"', '29.1"', '29.9"', '31.1"', '31.9"', '33.5"']; // INCH
    const imperialHalfChestValues = ['18.1"', '20.1"', '22"', '24"', '26"', '28"']; // INCH
    const metricLengthValues = [71, 74, 76, 79, 81, 85]; // cm
    const metriclHalfChestValues = [46, 51, 56, 61, 66, 71]; // cm

    const handleCloseSizeGuide = () => {
        setShowSizeGuide(false);
    };

    const handleMetricSwitch = metric => {
        setSizeGuideUnit(metric);
    };

    return (
        <div>
            {showSizeGuide && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 w-full h-full bg-black bg-opacity-50">
                    <div className="w-full h-full flex justify-center items-center ">
                        <div className="w-full sm:md:lg:w-1/2 px-2">
                            <div className=" rounded-lg shadow bg-white border-2">
                                <div className="flex justify-between px-4 pt-2 text-black">
                                    <div className=" space-x-4">
                                        <button
                                            onClick={() => handleMetricSwitch('imperial')}
                                            className={`${sizeGuideUnit === 'imperial' ? 'border-b-2' : ''}`}
                                        >
                                            Imperial (Inches)
                                        </button>
                                        <button
                                            onClick={() => handleMetricSwitch('metric')}
                                            className={`${sizeGuideUnit === 'metric' ? 'border-b-2' : ''}`}
                                        >
                                            Metric (Cm)
                                        </button>
                                    </div>
                                    <button
                                        onClick={handleCloseSizeGuide}
                                        type="button"
                                        className="text-black bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-[#f3f4f6] hover:text-black"
                                        data-modal-hide="defaultModal"
                                    >
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <table className="min-w-full bg-white border rounded text-center mt-2">
                                    <thead>
                                        <tr>
                                            <th className="py-2 px-4 border w-1/6">Size</th>
                                            <th className="py-2 px-4 border w-2/6">
                                                Length ({sizeGuideUnit === 'imperial' ? 'in' : 'cm'})
                                            </th>
                                            <th className="py-2 px-4 border w-2/6">
                                                Half Chest ({sizeGuideUnit === 'imperial' ? 'in' : 'cm'})
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sizes.map((size, index) => (
                                            <tr key={index}>
                                                <td className="py-2 px-4 border font-bold">{size}</td>
                                                <td className="py-2 px-4 border">
                                                    {sizeGuideUnit === 'imperial'
                                                        ? imperialLengthValues[index]
                                                        : metricLengthValues[index]}
                                                </td>
                                                <td className="py-2 px-4 border">
                                                    {sizeGuideUnit === 'imperial'
                                                        ? imperialHalfChestValues[index]
                                                        : metriclHalfChestValues[index]}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SizeGuide;
