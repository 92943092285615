import React, { useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';

const Feedback = ({ handleFeedbackLogClick }) => {
    const [userEmail, setUserEmail] = useState(null);
    const [userSubject, setuserSubject] = useState(null);
    const [userFeedback, setuserFeedback] = useState(null);

    const handleSendMessage = event => {
        if (userEmail !== null || userFeedback !== null) {
            event.preventDefault();
            handleFeedbackLogClick(userEmail, userSubject, userFeedback);
            window.close();
        }
    };

    return (
        <div className="">
            <section className="bg-white rounded-lg border-2 border">
                <div className="py-6  px-4 mx-auto max-w-screen-md">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-black ">Contact Us</h2>
                    <p className="mb-6 text-center text-gray-600 sm:text-xl font-bold">
                        Got a technical issue? Want to send feedback about a beta feature? Let us know.
                    </p>
                    <form action="#" className="space-y-8">
                        <div>
                            <label
                                htmlFor="email"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >
                                Your email
                            </label>
                            <input
                                type="email"
                                id="email"
                                autoComplete="off"
                                className="shadow-md shadow-gray-600 border-2 border-gray-800 text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-[#f3f4f6]"
                                placeholder="name@mail.com"
                                required
                                onChange={event => setUserEmail(event.target.value)}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="subject"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >
                                Subject
                            </label>
                            <input
                                type="text"
                                id="subject"
                                autoComplete="off"
                                className="shadow-md shadow-gray-600 border-2 border-gray-800 text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-[#f3f4f6]"
                                placeholder="Let us know how we can help you"
                                required
                                onChange={event => setuserSubject(event.target.value)}
                            />
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="message"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >
                                Your message
                            </label>
                            <textarea
                                id="message"
                                rows={6}
                                className="shadow-md shadow-gray-600 border-2 border-gray-800 text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-[#f3f4f6]"
                                placeholder="Leave a comment..."
                                onChange={event => setuserFeedback(event.target.value)}
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                onClick={handleSendMessage}
                                className="w-26 text-white border border-gray-700 font-bold rounded-lg text-md px-6 py-2 mt-2 shadow-md shadow-gray-900 bg-neutral-900 hover:bg-neutral-700"
                            >
                                <div className="flex justify-center items-center">
                                    <span>Send Message</span><AiOutlineSend className={'ml-2'} size={20}></AiOutlineSend>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default Feedback;
