import { debounce } from 'lodash';
import moment from 'moment/moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import { IoImage, IoImageOutline } from 'react-icons/io5';
import { LuImageMinus, LuImagePlus } from 'react-icons/lu';
import { PiShootingStarFill } from 'react-icons/pi';
import { RiImageEditFill } from 'react-icons/ri';
import { FaCog, FaPlay } from 'react-icons/fa';
import { MdFileUpload } from 'react-icons/md';
import { Link } from 'react-scroll';
import ImageContext, { Image } from '../contexts/ImageContext';
import { UserContext } from '../contexts/UserContext';
import ErrorPrompts from './ErrorPrompts';
import { ImageEdit } from './ImageEdit';
import { apiGatewayClient, createGetImagePayload, isUsingMobile, uploadToS3 } from './utils';

enum SupportedFileTypes {
    JPEG = 'image/jpeg',
    JPG = 'image/jpg',
    PNG = 'image/png'
}

enum EditTypes {
    EDIT = 'edit',
    ADD = 'add',
    REMOVE = 'remove',
}

const editOptions: Record<EditTypes, Record<string, string>> = {
    [EditTypes.EDIT]: {
        optionLabel: 'Modify',
        buttonLabel: 'Modifying Element'
    },
    [EditTypes.ADD]: {
        optionLabel: 'Add',
        buttonLabel: 'Adding Element'
    },
    [EditTypes.REMOVE]: {
        optionLabel: 'Remove',
        buttonLabel: 'Removing Element'
    }
};

interface StylizeOption {
    optionText: string,
    optionType: string,
}

const stylizeOptions: Record<string, StylizeOption> = {
    enhance: {
        optionText: 'Enhance',
        optionType: 'enhance'
    },
    anime: {
        optionText: 'Anime',
        optionType: 'anime'
    },
    comicBook: {
        optionText: 'Comic Book',
        optionType: 'comic-book'
    },
    lineArt: {
        optionText: 'Line Art',
        optionType: 'line-art'
    },
    neonPunk: {
        optionText: 'Neon Punk',
        optionType: 'neon-punk'
    },
    isometric: {
        optionText: 'Isometric',
        optionType: 'isometric'
    },
    lowPoly: {
        optionText: 'Low Poly',
        optionType: 'low-poly'
    },
    origami: {
        optionText: 'Origami',
        optionType: 'origami'
    },
    pixelArt: {
        optionText: 'Pixel Art',
        optionType: 'pixel-art'
    },
    tileTexture: {
        optionText: 'Tile Texture',
        optionType: 'tile-texture'
    }
};


export const Landing = ({handleClick, modError, setModError, successMessage, setSuccessMessage, setIsSettingsPage}) => {
    const [ displayImages, setDisplayImages ] = useState([]);
    const [ displayDiv, setDisplayDiv ] = useState(false);
    const { setSelectedImageURL, setSelectedImageID, setSelectedImageDefaultColor } = useContext(ImageContext);
    const [ selectedImage, setSelectedImage ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ removeBackground, setRemoveBackground ] = useState(false);
    const [ searchText, setSearchText ] = useState('');
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ noPromptMessage, setNoPromptMessage ] = useState(null);
    const [ hovered, setHovered ] = useState(false);
    const [ availableCredits, setAvailableCredits ] = useState(0);
    const [ showEditPanel, setShowEditPanel ] = useState(false);
    const [ editMask, setEditMask ] = useState(null);
    const [ showStylizeOptions, setShowStylizeOptions] = useState(false);
    const [ showEditOptions, setShowEditOptions] = useState(false);
    const [ selectedEditOption, setSelectedEditOption ] = useState(null);
    const [ showRelatedImages, setShowRelatedImages ] = useState(false);
    const [ relatedImages, setRelatedImages ] = useState([]);
    const [ groupedHistory, setGroupedHistory ] = useState({});
    const [ expandedIndex, setExpandedIndex ] = useState(null);
    const [ recentRootImageId, setRecentRootImageId ] = useState(null);

    const { user_ID } = useContext(UserContext);

    const fileInputRef = useRef(null);
    const inputRef = useRef(null);
    const timerRef = useRef(null);
    const scrollDiv = useRef(null);
    const verticalScrollDiv = useRef(null);
    const imageEditRef = useRef(null);
    const isFirstRender = useRef(true);

    const clearCanvas = () => {
        if (imageEditRef.current) {
            imageEditRef.current.clearCanvas();
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const showRelatedImagesOnClick = (rootImageId: string, index: number, imageId: string) => {
        if (expandedIndex === index) {
            setShowRelatedImages(!showRelatedImages);
        } else {
            setShowRelatedImages(false);
            setShowRelatedImages(true);
        }
        setRelatedImages(groupedHistory[rootImageId]
            .filter((image: Image) => image.imageId !== displayImages[index].imageId));
    };

    const getNumberOfRelatedImages = (rootImageId: string, imageId: string) => {
        return groupedHistory[rootImageId] ?
            groupedHistory[rootImageId].length - 1 : groupedHistory[imageId]?.length - 1;
    };

    const closeEdit = () => {
        setShowEditPanel(false);
    };

    const fetchCredits = async () => {
        try {
            const response = await apiGatewayClient({
                path: 'get_available_credits',
                method: 'GET',
                queryParams: {
                    user_id: user_ID
                }
            });
            const data = await response.json();
            setAvailableCredits(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getImgHistory = async () => {
        if (user_ID) {
            if (!displayDiv) {
                setIsLoading(true);
                setDisplayImages((prevState) => [{ isLoading: true }, ...prevState]);
            }
            setDisplayDiv(true);
            try {
                const response = await apiGatewayClient({
                    path: 'get_image_history',
                    method: 'POST',
                    body: {
                        user_id: user_ID,
                        current_num_photos: displayImages.length
                    }
                });
                const resObject: object = await response.json();
                Object.keys(resObject).forEach((key) => {
                    groupedHistory[key] = resObject[key].map((img) => ({
                        imageUrl: img.image_url,
                        imageId: img.image_id,
                        favorited: img.favorited,
                        defaultShirtColor: img.default_shirt_color,
                        seed: img.seed,
                        timestamp: moment(img.timestamp).toISOString(),
                        baseImageId: img.base_image_id,
                        rootImageId: img.root_image_id
                    }));
                });
                const shallowHistory: Image[] = Object.values(resObject)
                    .map((imageList) => {
                        const firstImage = imageList[0];
                        return {
                            imageUrl: firstImage.image_url,
                            imageId: firstImage.image_id,
                            favorited: firstImage.favorited,
                            defaultShirtColor: firstImage.default_shirt_color,
                            seed: firstImage.seed,
                            timestamp: moment(firstImage.timestamp).toISOString(),
                            baseImageId: firstImage.base_image_id,
                            rootImageId: firstImage.root_image_id
                        };
                    }).sort((a, b) => moment(b.timestamp).diff(moment(a.timestamp)));

                if (shallowHistory.length > 0) {
                    setDisplayImages((prevState) => {
                        let result: Image[];
                        if (prevState[0].isLoading) {
                            result = [ ...prevState.slice(1), ...shallowHistory ];
                        } else {
                            result = [ ...prevState, ...shallowHistory ];
                        }
                        if (!selectedImage) {
                            setImageProps(result[0]);
                        }
                        return result;
                    });
                } else {
                    setDisplayImages([]);
                }
            } catch (error) {
                console.error('Error:', error);
                setErrorMessage('Error happened while fetching image history, please try again');
                setModError(true);
                setDisplayImages([]);
            } finally {
                setIsLoading(false);
            }
        }
    };
    const handleWheel = (e) => {
        if (!scrollDiv.current) return;
        e.preventDefault();
        const scrollSpeedMultiplier = 5;
        const horizontalScrollAmount = e.deltaY;
        scrollDiv.current.scrollLeft += horizontalScrollAmount * scrollSpeedMultiplier;
    };

    useEffect(() => {
        const currentScrollDiv = scrollDiv.current;
        if (currentScrollDiv) {
            currentScrollDiv.addEventListener('wheel', handleWheel, { passive: false });
        }

        return () => {
            if (currentScrollDiv) {
                currentScrollDiv.removeEventListener('wheel', handleWheel, { passive: false });
            }
        };
    }, []);

    
    const handleEditEvent = async (editType: EditTypes) => {
        if (availableCredits <= 0) {
            setModError(true);
            setErrorMessage('You do not have enough credits to generate an image.');
        } else {
            try {
                setIsLoading(true);
                setDisplayImages([ { isLoading: true }, ...displayImages ]);
                setShowRelatedImages(false);
                const result = await apiGatewayClient({
                    path: 'get_edited_image',
                    method: 'POST',
                    body: {
                        edit_type: editType,
                        user_id: user_ID,
                        base_image_id: selectedImage.imageId,
                        prompt: searchText,
                        mask_base64: editMask,
                        remove_background: removeBackground,
                        root_image_id: selectedImage.rootImageId ?? selectedImage.imageId
                    }
                });
                const data = await result.json();
                const editedImages: Image[] = data.map((imgDict) => ({
                    imageUrl: imgDict.image_url,
                    imageId: imgDict.image_id,
                    defaultShirtColor: imgDict.default_shirt_color,
                    seed: imgDict.seed,
                    timestamp: moment(imgDict.timestamp).toISOString(),
                    baseImageId: imgDict.base_image_id,
                    rootImageId: imgDict.root_image_id,
                    favorited: imgDict.favorited
                }));
                const firstImage: Image = editedImages[0];
                setRecentRootImageId(firstImage.rootImageId);
                groupedHistory[firstImage.rootImageId] ?
                    groupedHistory[firstImage.rootImageId].push(firstImage) :
                    groupedHistory[firstImage.rootImageId] = [ firstImage ];
                setImageProps(firstImage);
                setDisplayImages(prevState => {
                    const shallowIndex =
                        prevState.findIndex((image: Image) => image.rootImageId === firstImage.rootImageId);
                    prevState = [
                        ...prevState.slice(0, shallowIndex),
                        ...prevState.slice(shallowIndex + 1, prevState.length)
                    ];
                    return [ ...prevState ];
                });
                setDisplayImages(prevState => [ ...editedImages, ...prevState.slice(1) ]);
                clearCanvas();
                await fetchCredits();
            } catch (e) {
                console.error('Error happened during edit request', e);
                setErrorMessage('Error happened during edit request, please try again');
                setModError(true);
                setDisplayImages(prevState => [ ...prevState.slice(1) ]);
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (user_ID && !isFirstRender.current) {
            const fetchData = async () => {
                try {
                    await fetchCredits();
                    await getImgHistory();
                } catch (error) {
                    console.error('Error fetching data', error);
                    setErrorMessage(error.message);
                    setModError(true);
                }
            };
            fetchData();
        } else {
            isFirstRender.current = false;
        }
    }, [user_ID]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const setImageProps = (image: Image) => {
        setSelectedImageURL(image.imageUrl);
        setSelectedImageID(image.imageId);
        setSelectedImageDefaultColor(image.defaultShirtColor);
        setSelectedImage(image);
    };

    const handleFileUploadEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file: File = event.target.files[0];
            if (!file) {
                return undefined;
            }
            if (Object.values(SupportedFileTypes).includes(file.type as SupportedFileTypes)) {
                try {
                    setShowRelatedImages(false);
                    setDisplayImages([ { isLoading: true }, ...displayImages ]);
                    setDisplayDiv(true);
                    setIsLoading(true);

                    const s3preSignedUrl = await apiGatewayClient({
                        path: 'get_presigned_url',
                        method: 'POST',
                        body: {
                            file_name: file.name
                        }
                    }).then(response => response.json());

                    await uploadToS3(s3preSignedUrl['presigned_url'], file);

                    const preparedImage: Image = await apiGatewayClient({
                        path: 'prepare_uploaded_image',
                        method: 'POST',
                        body: {
                            file_name: s3preSignedUrl['file_name'],
                            user_id: user_ID
                        }
                    }).then(async res => {
                        const result = await res.json();
                        return {
                            imageUrl: result.image_url,
                            imageId: result.image_id,
                            favorited: result.favorited,
                            defaultShirtColor: result.default_shirt_color,
                            seed: result.seed,
                            timestamp: moment(result.timestamp).toISOString(),
                            baseImageId: result.base_image_id,
                            rootImageId: result.root_image_id
                        };
                    });
                    setRecentRootImageId(preparedImage.rootImageId);
                    groupedHistory[preparedImage.rootImageId] ?
                        groupedHistory[preparedImage.rootImageId].push(preparedImage) :
                        groupedHistory[preparedImage.rootImageId] = [ preparedImage ];
                    setImageProps(preparedImage);
                    setDisplayImages(prevState => [ preparedImage, ...prevState.slice(1) ]);
                } catch (e) {
                    console.error(e);
                    setModError(true);
                    setErrorMessage(e.message);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setModError(true);
                setErrorMessage('Only PNG and JPEG images are allowed');
            }
        }
    };

    const handleGeneration = async () => {
        handleClick('Landing', 'Generate Button', '');
        if (availableCredits <= 0) {
            setModError(true);
            setErrorMessage('You do not have enough credits to generate an image.');
        } else {
            try {
                setIsLoading(true);
                setDisplayImages([ { isLoading: true }, ...displayImages ]);
                setDisplayDiv(true);
                setShowRelatedImages(false);

                const getImagePayload = createGetImagePayload(user_ID, searchText, null, null, true, removeBackground);
                const response = await apiGatewayClient({
                    path: 'get_images',
                    method: 'POST',
                    body: getImagePayload
                });
                const responseData = await response.json();
                const generatedImages: Image[] = responseData.map(imgDict => ({
                    imageUrl: imgDict.image_url,
                    imageId: imgDict.image_id,
                    favorited: imgDict.favorited,
                    baseImageId: imgDict.base_image_id,
                    rootImageId: imgDict.root_image_id,
                    seed: imgDict.seed,
                    timestamp: moment(imgDict.timestamp).toISOString(),
                    defaultShirtColor: imgDict.default_shirt_color
                }));
                const firstImage: Image = generatedImages[0];
                setRecentRootImageId(generatedImages[0].rootImageId);
                groupedHistory[firstImage.rootImageId] ?
                    groupedHistory[firstImage.rootImageId].push(firstImage) :
                    groupedHistory[firstImage.rootImageId] = [ firstImage ];
                setImageProps(firstImage);
                setDisplayImages(prevState => [ ...generatedImages, ...prevState.slice(1) ]);
                await fetchCredits();
            } catch(e) {
                setModError(true);
                setErrorMessage(e.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        setSelectedImageURL(null);
        setSelectedImageID(null);
        setIsSettingsPage(false);
        setModError(null);
    }, []);

    const handleMouseEnter = () => {
        setHovered(true);
        clearTimeout(timerRef.current);
    };

    const handleMouseLeave = () => {
        setHovered(false);
        timerRef.current = setTimeout(() => {
            setSuccessMessage(null);
            setModError(false);
            setErrorMessage(null);
            setNoPromptMessage(null);
        }, 5000);
    };

    const onErrorClick = () => {
        setModError(false);
        handleClick('Landing', 'Error Card Button', '');
    };

    const onSearchClick = () => {
        handleClick('Landing', 'Search Bar', '');
    };

    const handleStylizeEvent = async (selectedImage: Image, stylizeOption: StylizeOption) => {
        setShowStylizeOptions(false);
        handleClick('Landing', 'Generate Button', '');
        if (availableCredits <= 0) {
            setModError(true);
            setErrorMessage('You do not have enough credits to generate an image.');
        } else {
            try {
                setIsLoading(true);
                setDisplayImages([ { isLoading: true }, ...displayImages ]);
                setDisplayDiv(true);
                setShowRelatedImages(false);

                const stylizedImage: Image = await apiGatewayClient({
                    path: 'get_stylized_image',
                    method: 'POST',
                    body: {
                        user_id: user_ID,
                        base_image_id: `${selectedImage.imageId}.png`,
                        prompt: stylizeOption.optionType,
                        root_image_id: selectedImage.rootImageId ?? selectedImage.imageId,
                        remove_background: removeBackground
                    }
                }).then(async res => {
                    const result = await res.json();
                    const firstImage = result[0];
                    return {
                        imageUrl: firstImage.image_url,
                        imageId: firstImage.image_id,
                        favorited: firstImage.favorited,
                        defaultShirtColor: firstImage.default_shirt_color,
                        seed: firstImage.seed,
                        timestamp: moment(firstImage.timestamp).toISOString(),
                        baseImageId: firstImage.base_image_id,
                        rootImageId: firstImage.root_image_id
                    };
                });
                setRecentRootImageId(stylizedImage.rootImageId);
                groupedHistory[stylizedImage.rootImageId] ?
                    groupedHistory[stylizedImage.rootImageId].push(stylizedImage) :
                    groupedHistory[stylizedImage.rootImageId] = [ stylizedImage ];
                setImageProps(stylizedImage);
                setDisplayImages(prevState => {
                    const shallowIndex =
                        prevState.findIndex((image: Image) => image.rootImageId === stylizedImage.rootImageId);
                    prevState = [
                        ...prevState.slice(0, shallowIndex),
                        ...prevState.slice(shallowIndex + 1, prevState.length)
                    ];
                    return [ ...prevState ];
                });
                setDisplayImages(prevState => [ stylizedImage, ...prevState.slice(1) ]);
                await fetchCredits();
            } catch(e) {
                setModError(true);
                setErrorMessage(e.message);
                setDisplayImages(prevState => [ ...prevState.slice(1) ]);
            } finally {
                setIsLoading(false);
            }
        }
    };
    const handleImageClick = (image: Image) => {
        handleClick('Landing', 'Selected Image', image.imageId);
        setImageProps(image);
    };

    useEffect(() => {
        document.documentElement.classList.add('scrollbar-screen');
        return () => {
            document.documentElement.classList.remove('scrollbar-screen');
        };
    }, []);

    const createScrollHandler = () => {
        return debounce(async (e) => {
            if (isLoading) {
                return;
            }
            const threshold = 5;
            const endReached = e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth + threshold;
            if (endReached) {
                await getImgHistory();
            }
        }, 150);
    };

    const handleScroll = useCallback(createScrollHandler(), [getImgHistory, isLoading]);
    const debouncedHandleScroll = debounce(handleScroll, 150);

    useEffect(() => {
        const div = scrollDiv.current;
        if (div) {
            div.addEventListener('scroll', debouncedHandleScroll);
            return () => {
                div.removeEventListener('scroll', debouncedHandleScroll);
            };
        }
    }, [debouncedHandleScroll]);

    return (
        <div className={'flex justify-center w-full bg-white'}>
            {/* Landing Page */}
            <div className="px-2 w-screen md:w-[50rem] lg:w-[62.4rem] lg:ml-24 xl:ml-48 flex flex-col h-full z-49">
                <div
                    className="flex font-bold justify-center px-2 text-sm text-gray-600">
                    Credits Available: {availableCredits}
                </div>
                <div className="border-[3px] shadow-xl shadow-gray-600 rounded-lg">
                    <label
                        htmlFor="default-search"
                        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-black"
                    >
                Search
                    </label>
                    <div className="z-35">
                        <div className="top-0 flex flex-col rounded-r-lg rounded-b-lg template-search">
                            <div className="flex sm:md:lg:flex-row py-4 px-4 sm:md:lg:pl-4 w-full overflow-x-disabled sm:md:lg:overflow-x-hidden">
                                <div className="flex w-full min-w-max sm:md:lg:min-w-0 scrollbar-div">
                                    <div className="relative w-full">
                                        <textarea
                                            onClick={onSearchClick}
                                            maxLength={220}
                                            id={'Subject'}
                                            autoComplete="off"
                                            key={'Subject'}
                                            name={'Subject'}
                                            rows={3}
                                            className="focus:outline-gray-700 shadow-md shadow-gray-500 peer placeholder-transparent overflow-hidden resize-none h-full w-full p-4 rounded-lg border-2 border-gray-900 text-black"
                                            placeholder="Subject"
                                            onChange={event => setSearchText(event.target.value)}
                                            onKeyDown={async event => {
                                                if (event.key === 'Enter' && !isLoading) {
                                                    event.preventDefault();
                                                    if (!showEditPanel) await handleGeneration();
                                                }
                                            }}
                                        />
                                        <label
                                            htmlFor={'subject'}
                                            className="pointer-events-none absolute text-sm text-gray-500 duration-500 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[1.3rem] peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                                        >
                                            { showEditPanel ? 'What you would like to edit?' : 'Enter a prompt to generate an image!' }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={`${isUsingMobile() ? '' : 'flex'} justify-center justify-between 
                                            items-center sm:md:lg:h-30 px-4 
                                            pt-3 pt-1 border-2 ml-4 mr-4 rounded-lg 
                                            border-2 border-gray-300 shadow-md shadow-gray-300`}>
                                <div className={`mt-3 flex flex-row justify-center items-center ${isUsingMobile() ? 'gap-[0.2rem]' : 'gap-4'}`}>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileUploadEvent}
                                        accept="image/*"
                                    />
                                    <div className={'flex flex-col items-center'}>
                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 w-[4rem] whitespace-nowrap
                                            ${isLoading ? 'opacity-50 cursor-not-allowed' : ''} `}
                                            onClick={() => handleButtonClick()}
                                        >
                                            <div className={'flex justify-center items-center h-[3rem]'}>
                                                <MdFileUpload size={30} className={'text-green-500'}></MdFileUpload>
                                            </div>
                                        </button>
                                        <span className="text-xs mt-1 font-bold w-[4rem] text-center leading-tight">Upload Image</span>
                                    </div>
                                    <div className={'flex flex-col items-center'}>
                                        <button
                                            type="submit"
                                            disabled={isLoading || !selectedImage}
                                            className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 w-[4rem] whitespace-nowrap
                                            ${isLoading || !selectedImage ? 'opacity-50 cursor-not-allowed' : ''} `}
                                            onClick={() => {
                                                setShowStylizeOptions(!showStylizeOptions);
                                                if (showEditOptions) {
                                                    setShowEditOptions(false);
                                                }
                                            }}
                                        >
                                            <div className={'flex justify-center items-center h-[3rem]'}>
                                                <PiShootingStarFill size={30} className={'text-yellow-500'}></PiShootingStarFill>
                                            </div>
                                        </button>
                                        <span className="text-xs mt-1 font-bold w-[5rem] text-center leading-tight">Stylize Image</span>
                                        {showStylizeOptions && (
                                            <div className={`z-50 absolute mt-[3.4rem] w-[8rem] rounded-md bg-black transition ease-in-out duration-500 ${showStylizeOptions ? 'opacity-100' : 'opacity-0'}`}
                                                style={{ display: showStylizeOptions ? 'block' : 'none' }}>
                                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                                                    {Object.values(stylizeOptions).map((value, index) => {
                                                        return (
                                                            <a
                                                                key={index}
                                                                href="#"
                                                                className={`text-center block px-4 py-2 text-sm font-bold text-white bg-black 
                                                                hover:bg-gray-500 ${index === Object.values(stylizeOptions).length - 1 ? '' : 'border-b-[1px] border-slate-500'}`}
                                                                role="menuitem"
                                                                onClick={() => handleStylizeEvent(selectedImage, value)}
                                                            >
                                                                {value.optionText}
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={'flex flex-col items-center'}>
                                        <button
                                            type="submit"
                                            disabled={isLoading || !selectedImage}
                                            className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 w-[4rem] whitespace-nowrap
                                            ${isLoading || !selectedImage ? 'opacity-50 cursor-not-allowed' : ''} `}
                                            onClick={() => {
                                                setShowEditOptions(!showEditOptions);
                                                if (showStylizeOptions) {
                                                    setShowStylizeOptions(false);
                                                }
                                            }}
                                        >
                                            <div className={'flex justify-center items-center h-[3rem]'}>
                                                { !showEditPanel && <RiImageEditFill size={30} className={'text-purple-500'}></RiImageEditFill>}
                                                { (showEditPanel && selectedEditOption === EditTypes.EDIT) && <RiImageEditFill size={30} className={'text-orange-500'}></RiImageEditFill>}
                                                { (showEditPanel && selectedEditOption === EditTypes.ADD) && <LuImagePlus size={30} className={'text-green-500'}></LuImagePlus>}
                                                { (showEditPanel && selectedEditOption === EditTypes.REMOVE) && <LuImageMinus size={30} className={'text-red-500'}></LuImageMinus>}
                                            </div>
                                        </button>
                                        <span className="text-xs mt-1 font-bold w-[4rem] text-center leading-tight">{ showEditPanel ? editOptions[selectedEditOption]?.buttonLabel : 'Edit Image'}</span>
                                        {showEditOptions && (
                                            <div className={`z-50 absolute mt-[3.4rem] w-[8rem] rounded-md bg-black transition ease-in-out duration-500 ${showEditOptions ? 'opacity-100' : 'opacity-0'}`}
                                                style={{ display: showEditOptions ? 'block' : 'none' }}>
                                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                                                    {Object.values(editOptions).map((value, index) => {
                                                        return (
                                                            <a
                                                                key={index}
                                                                className={`text-center block px-4 py-2 text-sm font-bold text-white bg-black 
                                                                hover:bg-gray-500 ${index === Object.values(editOptions).length - 1 ? '' : 'border-b-[1px] border-slate-500'}`}
                                                                role="menuitem"
                                                                onClick={() => {
                                                                    const selectedType = Object.keys(editOptions)
                                                                        .find(key => editOptions[key] === value);
                                                                    setShowEditPanel(true);
                                                                    setShowEditOptions(false);
                                                                    setSelectedEditOption(selectedType);
                                                                }}
                                                            >
                                                                {value.optionLabel}
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={'flex flex-col items-center'}>
                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 w-[4rem] whitespace-nowrap
                                            ${isLoading ? 'opacity-50 cursor-not-allowed' : ''} `}
                                            onClick={() => setRemoveBackground(!removeBackground)}
                                        >
                                            {removeBackground ?
                                                (<div className={'flex justify-center items-center h-[3rem]'}>
                                                    <IoImageOutline size={30} className="text-red-500"/>
                                                </div>) :
                                                (<div className={'flex justify-center items-center h-[3rem]'}>
                                                    <IoImage size={30} className="text-green-500"/>
                                                </div>)}
                                        </button>
                                        {removeBackground ?
                                            (<span className={'text-xs mt-1 font-bold w-[5rem] text-center leading-tight'}>Background (Off)</span>) :
                                            (<span className={'text-xs mt-1 font-bold w-[5rem] text-center leading-tight'}>Background (On)</span>)}
                                    </div>
                                </div>
                                <div className={'flex flex-row items-center justify-center gap-2'}>
                                    <div className={'flex flex-col items-center mt-2'}>
                                        <button
                                            type="submit"
                                            disabled={(isLoading || !selectedImage)}
                                            className={`shadow-md shadow-gray-700 text-white border-2 border-[black] focus:ring-2 focus:ring-[#0369a1] focus:outline-none 
                                            font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 w-[4rem] whitespace-nowrap  
                                            ${isLoading ? 'opacity-50 cursor-not-allowed' : ''} `}
                                            onClick={() =>
                                                showEditPanel ? handleEditEvent(selectedEditOption) : handleGeneration()
                                            }
                                        >
                                            {showEditPanel ? (
                                                <div className={'flex justify-center items-center h-[3rem]'}>
                                                    <FaPlay className={'text-sky-400'} size={25}></FaPlay>
                                                </div>) : (<div className={'flex justify-center items-center h-[3rem]'}>
                                                <FaCog className={'text-sky-400'} size={30}></FaCog>
                                            </div>)}
                                        </button>
                                        {showEditPanel ?
                                            (<span
                                                className="text-xs mt-1 font-bold w-[4rem] text-center leading-tight mb-[0.9rem]">Submit ({editOptions[selectedEditOption].optionLabel})</span>) :
                                            (<span
                                                className="text-xs mt-1 font-bold w-[4rem] text-center leading-tight mb-[0.9rem]">Generate Image</span>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            {modError && (
                                <ErrorPrompts
                                    errorMessage={errorMessage}
                                    noPromptMessage={noPromptMessage}
                                    onErrorClick={onErrorClick}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    successMessage={successMessage}
                                />
                            )}
                        </div>
                    </div>
                    {displayDiv && (
                        <div className="pt-5">
                            <div>
                                {showEditPanel && <ImageEdit
                                    ref={imageEditRef}
                                    isLoading={isLoading}
                                    selectedImage={selectedImage}
                                    setEditMask={setEditMask}
                                    closeEdit={closeEdit}
                                ></ImageEdit>}
                            </div>
                            <div
                                ref={scrollDiv}
                                className={`flex flex-row overflow-x-auto scrollbar-div py-1 px-4 pb-12 z-20 rounded-lg ${showEditPanel && isUsingMobile() ? 'mt-[27rem]' : showEditPanel && !isUsingMobile() ? 'mt-[62rem]' : ''}`}
                            >
                                {displayImages.map((imageInfo: Image, index) => (
                                    <div key={index} className="flex-shrink-0 px-2 w-[18.75rem]">
                                        <Link to="productCard" smooth={true} duration={500}>
                                            {isLoading && index === 0 ? (
                                                <div className="flex justify-center items-center w-full h-[17rem] mx-2">
                                                    <div
                                                        className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-2"></div>
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="relative">
                                                        <img
                                                            onClick={() => handleImageClick(imageInfo)}
                                                            alt="Generated Image"
                                                            className={`${
                                                                selectedImage === imageInfo ? 'border-4 border-slate-400' : ''
                                                            } cursor-pointer h-auto w-full object-cover bg-black bg-opacity-0 hover:opacity-60 shadow-xl shadow-gray-900`}
                                                            src={imageInfo.imageUrl}
                                                        />
                                                        {getNumberOfRelatedImages(
                                                            imageInfo.rootImageId,
                                                            imageInfo.imageId) > 0 && (<button
                                                            className="shadow-md shadow-gray-700 text-white text-md border-2 border-slate-600 focus:ring-2 focus:ring-[#0369a1] focus:outline-none
                                                                   font-semibold rounded-md bg-neutral-900 hover:bg-neutral-700 duration-200 w-[4rem] h-[2rem] whitespace-nowrap absolute bottom-0 right-0 m-4"
                                                            onClick={() => {
                                                                showRelatedImagesOnClick(
                                                                    imageInfo.rootImageId, index, imageInfo.imageId
                                                                );
                                                                setExpandedIndex(index);
                                                            }}
                                                        >
                                                            <div
                                                                className={'flex flex-row justify-center items-center gap-1'}>
                                                                <span>
                                                                    +{getNumberOfRelatedImages(
                                                                        imageInfo.rootImageId,
                                                                        imageInfo.imageId)}
                                                                </span>
                                                                {
                                                                    (showRelatedImages && expandedIndex === index) ?
                                                                        <FaAngleUp></FaAngleUp> :
                                                                        <FaAngleDown></FaAngleDown>
                                                                }
                                                            </div>
                                                        </button>)}
                                                    </div>
                                                    {(showRelatedImages && expandedIndex === index) && (
                                                        <div ref={verticalScrollDiv} className={'h-[25rem] overflow-y-auto overflow-x-hidden scrollbar-div'}>
                                                            {relatedImages.map((imageInfo, index) => (
                                                                <div key={index}
                                                                    className="flex-shrink-0 px-2 w-[17.25rem] mt-2">
                                                                    <Link to="productCard" smooth={true} duration={500}>
                                                                        <img
                                                                            onClick={() => handleImageClick(imageInfo)}
                                                                            alt="Generated Image"
                                                                            className={`${
                                                                                selectedImage === imageInfo ? 'border-4 border-slate-400' : ''
                                                                            } cursor-pointer h-auto w-full object-cover bg-black bg-opacity-0 hover:opacity-60`}
                                                                            src={imageInfo.imageUrl}
                                                                        />
                                                                    </Link>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
