import { useGlobalProducts } from 'contexts/ProductsStore';
import React, { useEffect } from 'react';

const ProductOverlays = ({ image_url, color, product_id }) => {

    const products = useGlobalProducts();

    const product = products.find(product => product.product_id === product_id);

    return (
        <div className="relative z-35 w-1/2 md:w-1/3 lg:w-52 xl:w-60">
            <img
                className="w-full h-auto" 
                src={product.product_images[color]}
                alt="Shirt"
            />
            <img
                className="absolute max-w-100px max-h-100px top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 h-auto" 
                src={image_url}
                alt="Design"
            />
        </div>
    );
};

export default ProductOverlays;
