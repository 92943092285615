import { apiGatewayClient, getBackendEndpoint } from 'components/utils';
import { useState, useEffect } from 'react';

export const useIPandFeedbackLogger = () => {
    const [userIP, setUserIP] = useState(null);
    const [userProv, setUserProv] = useState(null);
    const [userCountry, setUserCountry] = useState(null);
    const [userCity, setUserCity] = useState(null);
    const [userActivities, setUserActivities] = useState([]);

    function getCurrentDateTime() {
        return new Date().toISOString();
    }

    const handleClick = (componentName, actionName, metadataImage) => {
        const currentDateTime = getCurrentDateTime();
        const userActivity = {
            //user_id: userEmail,
            city: userCity,
            province: userProv,
            country: userCountry,
            component: componentName,
            action: actionName,
            metadata: metadataImage,
            timestamp: currentDateTime
        };
        setUserActivities(prevLogs => {
            const updatedLogs = [...prevLogs, userActivity];
            return updatedLogs;
        });
    };

    const handleFeedbackLogClick = async (userEmail, userSubject, userFeedbackMessage) => {
        const currentDateTime = getCurrentDateTime();
        const userFeedback = {
            user_id: userIP,
            email: userEmail,
            subject: userSubject,
            feedback: userFeedbackMessage,
            timestamp: currentDateTime
        };
        // Send the feedback
        const response = await apiGatewayClient({
            path: 'store_feedback',
            method: 'POST',
            body: {
                userFeedback: userFeedback
            }
        });
        if (!response.ok) {
            // Handle the error
            console.error('Failed to send feedback:', response);
        } else {
            await response.json();
        }
    };

    useEffect(() => {
        async function pullIP() {
            const response = await apiGatewayClient({
                path: 'client_ip_data',
                method: 'GET'
            });
            return await response.json();
        }
        if (!userIP) {
            pullIP().then(data => {
                setUserIP(data.ip);
                setUserProv(data.region);
                setUserCountry(data.country_name);
                setUserCity(data.city);
            });
        }
    }, [userIP]);

    useEffect(() => {
        const sendLogs = async () => {
            if (userActivities.length > 0) {
                const data = JSON.stringify({ user_activities: userActivities });
                const blob = new Blob([data], { type: 'application/json' });

                navigator.sendBeacon(getBackendEndpoint() + 'store_activity', blob);
            }
        };
        window.addEventListener('beforeunload', sendLogs);

        return () => {
            window.removeEventListener('beforeunload', sendLogs);
        };
    }, [userActivities]);

    return { handleClick, userIP, handleFeedbackLogClick, getCurrentDateTime };
};
