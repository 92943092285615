import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoIosMore } from 'react-icons/io';
import { apiGatewayClient } from './utils';
import { UserContext } from '../contexts/UserContext';
import { AiOutlineSearch } from 'react-icons/ai';

const OrderHistory = () => {
    const [orderInfo, setOrderInfo] = useState<OrderInfoType[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<OrderInfoType[]>([]);
    const [searchOrderId, setSearchOrderId] = useState('');
    const [isOrderInfoFetched, setIsOrderInfoFetched] = useState(false);
    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const { user_ID } = useContext(UserContext);
    const orderListRef = useRef(null);

    type OrderInfoType = {
        timestamp: string; 
        status: string;
        order_id: string;
        shipping_address: {
            id: string;
            orderId: string;
            country: string;
            firstName: string;
            lastName: string;
            companyName?: string | null;
            addressLine1: string;
            city: string;
            postCode: string;
            state: string;
            email: string;
            phone?: string | null;
        };
        total: string;
    };
    

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const fetchOrderInfo = async () => {
        const queryParams = {
            user_id: user_ID,
            current_num_orders: orderInfo.length
        };
    
        try {
            if(isFirstFetch){
                setIsOrderInfoFetched(false);
                setIsFirstFetch(false);
            }
            const response = await apiGatewayClient({
                path: 'get_order_history',
                method: 'GET',
                queryParams: queryParams
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setOrderInfo(prevOrderInfo => {
                const combinedOrders = [...prevOrderInfo, ...data];
                const uniqueOrders = Array.from(new Map(combinedOrders.map(order => [order.order_id, order])).values());
                return uniqueOrders;
            });
            setIsOrderInfoFetched(true);
        } catch (error) {
            console.error('Error fetching order info:', error);
            throw error;
        }
    };

    const isBottom = (e) => {
        return e.getBoundingClientRect().bottom <= window.innerHeight;
    };
    
    const handleScroll = () => {
        const wrappedElement = orderListRef.current;
        if (wrappedElement && isBottom(wrappedElement)) {
            fetchOrderInfo();
        }
    };
    

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchOrderId(event.target.value);
    };

    useEffect(() => {
        const list = orderListRef.current;
        if(list) {
            list.addEventListener('scroll', handleScroll);
        }
        return () => {
            if(list) {
                list.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    
    useEffect(() => {
        if (user_ID) {
            fetchOrderInfo();
            console.log(user_ID);
        }
    }, [user_ID]);
    
    useEffect(() => {
        if (searchOrderId) {
            const filtered = orderInfo.filter(order => order.order_id.includes(searchOrderId));
            setFilteredOrders(filtered);
        } else {
            setFilteredOrders(orderInfo);
        }
    }, [searchOrderId, orderInfo]);

    useEffect(() => {
        document.documentElement.classList.add('scrollbar-screen');
        return () => {
            document.documentElement.classList.remove('scrollbar-screen');
        };
    }, []);

    return (
        <div className="container mx-auto px-4 sm:px-0">
            {isOrderInfoFetched ? (
                <div>
                    <div className='flex flex-col sm:flex-row items-baseline space-x-4 my-2'> 
                        <h1 className='font-bold text-xl'>Order History</h1>
                        <div className="relative w-full max-w-xs">
                            <input
                                type="text"
                                maxLength={220}
                                id="order_id"
                                autoComplete="off"
                                className="rounded-lg overflow-hidden h-10 w-full pl-4 pr-16 border-2 border-gray-300 focus:border-indigo-500 text-sm"
                                placeholder="Search by Order ID"
                                value={searchOrderId}
                                onChange={handleSearchChange}
                            />
                            <label
                                htmlFor={'order_id'}
                                className="pointer-events-none absolute right-2 top-0 h-full flex items-center text-gray-500"
                            >
                                <AiOutlineSearch size={20} className="mr-2" />
                            </label>
                        </div>
                    </div>
                    <div className="overflow-x-auto border rounded border-gray-200">
                        <table className="min-w-full bg-white">
                            <thead className="bg-white text-black text-center uppercase font-semibold text-sm">
                                <tr>
                                    <th className="py-3 px-4">Date</th>
                                    <th className="py-3 px-4">Order ID</th>
                                    <th className="py-3 px-4">Total</th>
                                    <th className="py-3 px-4">Order Status</th>
                                    <th className="py-3 px-4">Order Details</th> 
                                </tr>
                            </thead>
                            <tbody ref={orderListRef} className="text-gray-700 overflow-y-scroll">
                                {filteredOrders.map((order, index) => (
                                    <tr key={order.order_id} className='text-center border-b odd:bg-gray-100'>
                                        <td className="py-3 px-4">{formatDate(order.timestamp)}</td>
                                        <td className="py-3 px-4 underline">
                                            <a href={`/orders/${order.order_id}`} className="text-indigo-600 hover:text-indigo-800 underline">{order.order_id}</a>
                                        </td>
                                        <td className="py-3 px-4">${order.total}</td>
                                        <td className="py-3 px-4">{order.status.charAt(0).toUpperCase() + order.status.slice(1)}</td>
                                        <td className="py-3 px-4">
                                            <a href={`/orders/${order.order_id}`} className="text-indigo-600 hover:text-indigo-800 underline">Order Details</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex justify-end'>
                        <button
                            className="text-white mt-2 border border-gray-700 font-bold rounded-lg text-base px-3 py-2 mt-2 shadow-md shadow-gray-900 bg-neutral-900 hover:bg-neutral-700"
                            onClick={() => fetchOrderInfo()}
                        >
                            <div className="flex justify-center items-center space-x-2">
                                <span className="">Load More</span>
                                <IoIosMore size={25}/>
                            </div>
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex justify-center items-center w-full h-[17rem] mx-2">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-2"></div>
                    <span className="sr-only">Loading...</span>
                </div>
            )}
        </div>
    );
};

export default OrderHistory;